
<template>
  <div>
    <v-container class="my-10 py-5" >
      <v-row class="justify-center" align="stretch">
        <v-col cols="10" sm="10" md="5" lg="5" xl="5">
          <v-card color="transparent" rounded="xl" outlined>
            <v-img src="@/assets/contentPic/pic_AboutUs.png" rounded contain></v-img>
          </v-card>
        
        </v-col>
        <v-col cols="10" sm="10" md="5" lg="5" xl="5" class="d-flex align-center flex-wrap">
          <div>
            <p class="text-h5 text-sm-h4 text-md-h3 text-lg-h3 text-xl-h3 font-weight-black">Hi there, We are PromptBoom 🍻</p>
            <p class="mt-5 text-body-2 text-sm-body-1 text-md-body-1 text-lg-body-1 text-xl-body-1 font-weight-black">
              Our team consists of passionate technology enthusiasts committed to delivering the finest natural language processing service. For any inquiries or suggestions, please don't hesitate to reach out. We're here to help. Thank you for exploring our website!
            </p>
            <v-btn color="indigo" x-large class="mt-6" rounded @click="handleContact">
              <v-icon right color="white" class="mx-auto mr-4">
                mdi-human-greeting-variant
              </v-icon>
              <h6 class="text-capitalize white--text text-body-1 text-sm-h6 text-md-h6 text-lg-h6 text-xl-h6 font-weight-black">              
                Contact Us
              </h6>
            </v-btn>
          </div>
        </v-col>

      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'YA_HomeContent',
  data() {
    return {

    }
  },
  mounted() {

  },

  methods: {
    handleContact() {
      if (this.$route.path !== '/Contact') {
        this.$router.push({ path: '/Contact'});
      }
    },
  }

};
</script>

<style scoped>

</style>