import { render, staticRenderFns } from "./PowerFewShotRight.vue?vue&type=template&id=0d6d2f1a&scoped=true"
import script from "./PowerFewShotRight.vue?vue&type=script&lang=js"
export * from "./PowerFewShotRight.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d6d2f1a",
  null
  
)

export default component.exports