<template>
  <div>
    <v-card elevation="0" rounded="lg">
      <v-container class="d-flex justify-space-between flex-wrap">
        <div class="d-flex flex-wrap justify-center">
          <span class="pink--text text-body-1 font-weight-bold mt-1">
            <v-icon color="pink" class="mt-n1">mdi-collage</v-icon>
            Imitate:
          </span>
        </div>
        <div v-if="!$vuetify.breakpoint.smAndDown">
          <v-btn color="pink" class="mr-2" small @click="handleTryDemo">
            <h4 class="text-capitalize white--text font-weight-black">Try demo</h4>
            <v-icon size="20" class="ml-1" color="white">mdi-emoticon-wink</v-icon>
          </v-btn>
        </div>
        <v-container v-else class="d-flex justify-center mt-2 mb-n3">
          <v-btn color="pink" class="mr-2" small @click="handleTryDemo">
            <h4 class="text-capitalize white--text font-weight-black">Try demo</h4>
            <v-icon size="20" class="ml-1" color="white">mdi-emoticon-wink</v-icon>
          </v-btn>
        </v-container>
        
      </v-container>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'PowerImitateHead',
  data() {
    return {
    }
  },
  mounted() {
    this.handlePowerImitateChange();
  },

  methods: {
    handlePowerImitateChange() {
      let powerImitateHeadMsg = {
      }
      this.$store.commit('HandlepowerImitateHeadMsg', powerImitateHeadMsg);
    },
    handleTryDemo() {
      this.handlePowerImitateChange()
      this.$store.commit('HandlepowerImitateTryDemo', true);
    }
  }

};
</script>

<style scoped>

</style>