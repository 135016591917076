<template>
  <div>
    <!-- <YA_ScrollComponent :scrollHeight="55"> -->
      <PowerTranslateMain ref="mainHeight" />

      <div>
        <PowerTranslateContent />
      </div>

      <v-footer inset padless>
        <YA_footer />
      </v-footer>
    <!-- </YA_ScrollComponent> -->
  </div>
</template>

<script>
import YA_footer from '@/components/WebBar/YA_footer.vue';
import YA_ScrollComponent from '@/components/WebBar/YA_ScrollComponent.vue';
import PowerTranslateMain from '@/components/PowerTranslate/PowerTranslateMain.vue';
import PowerTranslateContent from '@/components/PowerTranslate/PowerTranslateContent.vue';


export default {
  
  name: 'PowerTranslateView',
  components: {
    YA_footer,
    // YA_ScrollComponent,
    PowerTranslateMain,
    PowerTranslateContent
  },
  data() {
    return {

    }
  },
  created() {
    this.$store.watch(
      (state) => state.powerTranslateMsg.powerTranslateMainHeight, () => {
        if (this.$store.getters.getpowerTranslateMainHeight) {
          window.scrollTo({
            top: this.$refs.mainHeight.$el.clientHeight,
            behavior: 'smooth',
          });
          this.$store.commit('HandlepowerTranslateMainHeight', false);
        }
      }, {
        deep: true
      }
    );
  },
  mounted() {

  },

  methods: {

  }

};
</script>

<style scoped>
</style>