<template>
  <div>
    <v-card elevation="0" rounded="lg">
      <v-container class="d-flex justify-space-between flex-wrap">
        <div class="d-flex flex-wrap justify-center">
          <span class="purple--text text-body-1 font-weight-bold mt-1">
            <v-icon color="purple" class="mt-n1">mdi-collage</v-icon>
            Modes:
          </span>
          <div style="width: 200px; " class="ml-xl-4 ml-lg-4 ml-md-4 mt-n1 mb-n4 mb-xl-n8 mb-lg-n8 mb-md-n8">
            <v-select
              color="purple"
              class="font-weight-bold"
              v-model="modesTagsSelected"
              :menu-props="{ maxHeight: '300', offsetY: true }"
              :items="modesTags"
              label="Choose Modes"
              @change="handlePowerRewriteChange"
              dense
              outlined
            ></v-select>
          </div>
          <div class="d-flex mt-1 ml-1 ml-xl-10 ml-lg-10 ml-md-10">
            <span class="purple--text text-body-1 font-weight-bold">
              Synonyms:
            </span>
            <v-tooltip bottom min-width="300">
              <template v-slot:activator="{ on, attrs }">
                <div class="mx-4" style="width: 200px; height: 25px;" v-bind="attrs" v-on="on">
                  <v-slider
                    v-model="lengthSlider"
                    step="50"
                    track-color="purple"
                    ticks="always"
                    tick-size="5"
                    dense
                    color="purple"
                    thumb-label
                    thumb-size="40"
                    @change="handlePowerRewriteChange"
                    hide-details
                  >
                    <template v-slot:thumb-label="{ value }">
                      {{ thumbLable[Math.min(Math.floor(value / 30), 2)] }}
                    </template>
                  </v-slider>
                </div>
              </template>
              <v-row justify-center no-gutters>
                <v-col cols="6" class="d-flex justify-center">
                  <span class="text-body-2 font-weight-bold">Fewer Changes</span>
                </v-col>
                <v-col cols="6" class="d-flex justify-center">
                  <span class="text-body-2 font-weight-bold">More Changes</span>
                </v-col>
              </v-row>
            </v-tooltip>
            
            <!-- <span class="grey--text text--darken-1 text-body-2 mt-1">
              long
            </span> -->
          </div>
        </div>
        <div v-if="!$vuetify.breakpoint.smAndDown">
          <v-btn color="purple" class="mr-2" small @click="handleTryDemo">
            <h4 class="text-capitalize white--text font-weight-black">Try demo</h4>
            <v-icon size="20" class="ml-1" color="white">mdi-emoticon-wink</v-icon>
          </v-btn>
        </div>
        <v-container v-else class="d-flex justify-center mt-2 mb-n3">
          <v-btn color="purple" class="mr-2" small @click="handleTryDemo">
            <h4 class="text-capitalize white--text font-weight-black">Try demo</h4>
            <v-icon size="20" class="ml-1" color="white">mdi-emoticon-wink</v-icon>
          </v-btn>
        </v-container>
        
      </v-container>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'PowerRewriteHead',
  data() {
    return {
      modesTagsSelected: 'Auto',
      modesTags: ['Auto', 'Formal','Casual', 'Fluency', 'Simple', 'Creative'],

      lengthSlider: 0,
      thumbLable: ['Fewer', 'Mid', 'More'],
      // tickLable: ['Fewer Changes', 'Mid Changes', 'More Changes']
    }
  },
  mounted() {
    this.handlePowerRewriteChange();
  },

  methods: {
    handlePowerRewriteChange() {
      let powerRewriteHeadMsg = {
        'modes': this.modesTagsSelected,
        'synonyms': this.thumbLable[Math.min(Math.floor(this.lengthSlider / 30), 2)]
      }
      this.$store.commit('HandlepowerRewriteHeadMsg', powerRewriteHeadMsg);
    },
    handleTryDemo() {
      this.modesTagsSelected = 'Formal'
      this.lengthSlider = 50
      this.handlePowerRewriteChange()
      this.$store.commit('HandlepowerRewriteTryDemo', true);
    }
  }

};
</script>

<style scoped>

</style>