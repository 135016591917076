<template>
  <div>
    <!-- <YA_ScrollComponent :scrollHeight="55"> -->

      <div class="d-flex justify-center flex-wrap mt-8 pb-8">
        <div>
          <p class="blue-grey--text text--darken-4 text-center text-h5 text-sm-h4 text-md-h3 text-lg-h3 text-xl-h3 font-weight-black mt-8">Subscribe to PromptBoom</p>
          <!-- <p class="text-center mt-6 text-body-2 text-sm-body-1 text-md-body-1 text-lg-body-1 text-xl-body-1">One Account, One Subscription, Infinite AI Possibilities 🚀</p> -->
          <p class="text-center mt-6 text-body-2 text-sm-body-1 text-md-body-1 text-lg-body-1 text-xl-body-1">Your All-in-One AI Assistant 🚀</p>


        </div>
      </div>

      <div class="pb-8" transition="scroll-y-transition">
        <YA_HomePricing />
      </div>

      <div style="background-color: rgb(250,250,252);" class="mt-8 pb-8">
        <YA_Content_GO />
      </div>

      <v-footer padless>
        <YA_footer />
      </v-footer>
    <!-- </YA_ScrollComponent> -->

  </div>
</template>

<script>
import YA_HomePricing from '@/components/WebPricing/YA_HomePricing.vue';
import YA_Content_GO from '@/components/WebBar/YA_Content_GO.vue';
import YA_footer from '@/components/WebBar/YA_footer.vue';
import YA_ScrollComponent from '@/components/WebBar/YA_ScrollComponent.vue';


export default {

  name: 'PricingView',
  components: {
    YA_HomePricing,
    YA_Content_GO,
    YA_footer,
    // YA_ScrollComponent,
  },
  data() {
    return {
      settings: {
        suppressScrollY: false,
        suppressScrollX: false,
        wheelPropagation: false
      },
    }
  },
  created() {
  },
  beforeDestroy() {
  },
  mounted() {

  },

  methods: {
  }

};
</script>

<style scoped>
</style>