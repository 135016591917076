<template>
  <div>
    <!-- <YA_ScrollComponent :scrollHeight="55"> -->
      <div>
        <YA_AboutTitle />
      </div>

      <div style="background-color: rgb(250,250,252);" class="py-10">
        <v-container>
          <v-row class="justify-center">
            <v-col cols="11" sm="11" md="11" lg="11" xl="11" class="d-flex justify-center">
              <v-card width="1200" flat color="transparent">
                <YA_AboutProducts />
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>

      <!-- <div class="mt-16">
        <p class="blue-grey--text text--darken-4 text-center text-h5 text-sm-h4 text-md-h5 text-lg-h5 text-xl-h5 font-weight-black">One Account, One Subscription, Infinite AI Possibilities</p>
      </div> -->

      <div class="pt-2 pb-2">
        <YA_AboutContact />
      </div>

      <div style="background-color: rgb(250,250,252);" class="pt-2 pb-2">
        <YA_AboutPricing />
      </div>

      <!-- <div>
        <YA_HomeContent3 />
      </div> -->

      <div class="my-8">
        <YA_Content_GO />
      </div>

      <v-footer padless>
        <YA_footer />
      </v-footer>
    <!-- </YA_ScrollComponent> -->

  </div>
</template>

<script>

import YA_HomeContent from '@/components/WebAbout/YA_HomeContent.vue';
import YA_HomeContent2 from '@/components/WebAbout/YA_HomeContent2.vue';
import YA_HomeContent3 from '@/components/WebAbout/YA_HomeContent3.vue';
import YA_AboutTitle from '@/components/WebAbout/YA_AboutTitle.vue';
import YA_AboutProducts from '@/components/WebAbout/YA_AboutProducts.vue';
import YA_AboutContact from '@/components/WebAbout/YA_AboutContact.vue';
import YA_AboutPricing from '@/components/WebAbout/YA_AboutPricing.vue';
import YA_Content_GO from '@/components/WebBar/YA_Content_GO.vue';
import YA_footer from '@/components/WebBar/YA_footer.vue';
import YA_ScrollComponent from '@/components/WebBar/YA_ScrollComponent.vue';


export default {
  name: 'AboutView',
  data() {
    return {

    }
  },
  components: {
    // YA_HomeContent,
    // YA_HomeContent2,
    // YA_HomeContent3,
    YA_AboutTitle,
    YA_AboutProducts,
    YA_AboutContact,
    YA_AboutPricing,
    YA_Content_GO,
    YA_footer,
    // YA_ScrollComponent,
  },
  mounted() {

  },

  methods: {

  }

};
</script>

<style scoped>

</style>