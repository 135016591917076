
<template>
  <div>
    <v-container class="my-10 py-5" >
      <v-row class="justify-center">
        <v-col cols="10" sm="10" md="10" lg="10" xl="10" class="d-flex justify-center flex-wrap">
          <v-avatar size="84">
            <v-img alt="avatar" contain src="@/assets/P.png" />
          </v-avatar>
          <div class="text-center">
            <p class="text-h5 text-sm-h4 text-md-h3 text-lg-h3 text-xl-h3 font-weight-black">PromptBoom</p>
            <p class="mt-5 text-body-2 text-sm-body-1 text-md-body-1 text-lg-body-1 text-xl-body-1 font-weight-black">
              PromptBoom is your All-In-One AI Platform, combining our most advanced AI models and a bundle of 10+ AI tools into a single subscription plan, which is more cost-effective than paying for each tool separately.🎉
            </p>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'YA_HomeContent3',
  data() {
    return {

    }
  },
  mounted() {

  },

  methods: {
  }

};
</script>

<style scoped>

</style>