
<template>
  <div>
    <v-menu 
      offset-y 
      no-border
      rounded
      :nudge-width="200"
      offset-x
    >
      <template v-slot:activator="{ on, attrs }">
        <v-avatar v-on="on" v-bind="attrs" class="mr-2 ml-2" size="36" color="indigo">
          <!-- <v-img alt="avatar" contain :src="photoUrl" /> -->
          <v-icon dark>
            mdi-account-circle
          </v-icon>
        </v-avatar>
      </template>
      <v-card>
        <v-list color="transparent">
          <v-list-item>
            <v-list-item-avatar color="indigo">
              <v-icon dark>
                mdi-account-circle
              </v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="grey--text text--darken-4">{{ userName }}</v-list-item-title>
              <v-list-item-subtitle class="grey--text text--darken-2">{{ userID }}</v-list-item-subtitle>
            </v-list-item-content>

          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <div>
          <div class="d-flex ma-4 align-center justify-center" v-if="IsProUser">
            <v-icon color="amber accent-4">mdi-crown</v-icon>
            <h5 class="text-center">Your current plan:Pro Plan</h5>
          </div>
          <div class="d-flex ma-4 align-center justify-center" v-else>
            <v-btn small color="blue" rounded @click='handlePricing'>
              <span class="ma-2 text-capitalize white--text">Upgrade plan</span>
            </v-btn>
          </div>
<!--           <div class="mb-2" v-show='!IsProUser'>
            <h5 class="text-center grey--text text--darken-2">Free document upload today: <span class="green--text text--darken-4">{{ DocAddNum }}</span></h5>
          </div> -->
          <div class="mb-4" v-show='!IsProUser'>
            <h5 class="text-center grey--text text--darken-2">Free messages left today : <span class="green--text text--darken-4">{{ ChatUseNum }}</span></h5>
          </div>
        </div>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="error"
            text
            @click="handleLogout"
            class="text-capitalize"
          >
            Sign out
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { getLocalStorage, setLocalStorage, removeLocalStorage } from '@/utils/SystemUtils/localStorage.js'

export default {
  name: 'YA_Logo',
  data() {
    return {
      userID: '',
      // photoUrl: require(`@/assets/pic_user.png`),
      IsProUser: false,
      userName: '',
      // RobotCreateNum: 0,
      ChatUseNum: 0,
      DocAddNum: 0,
    }
  },

  created() {
    this.$store.watch(
      (state) => state.userMsg.userID, () => {
        this.userID = this.$store.getters.getuserID;
      }, {
        deep: true
      }
    );
    this.userID = this.$store.getters.getuserID;

    this.$store.watch(
      (state) => state.userMsg.userName, () => {
        this.userName = this.$store.getters.getuserName;
      }, {
        deep: true
      }
    );
    this.userName = this.$store.getters.getuserName;

    this.$store.watch(
      (state) => state.userMsg.IsProUser, () => {
        this.IsProUser = this.$store.getters.getIsProUser;
      }, {
        deep: true
      }
    );
    this.IsProUser = this.$store.getters.getIsProUser;

    this.$store.watch(
      (state) => state.userMsg.DocAddNum, () => {
        this.DocAddNum = this.$store.getters.getDocAddNum;
      }, {
        deep: true
      }
    );
    this.DocAddNum = this.$store.getters.getDocAddNum;

    this.$store.watch(
      (state) => state.userMsg.ChatUseNum, () => {
        this.ChatUseNum = this.$store.getters.getChatUseNum;
      }, {
        deep: true
      }
    );
    this.ChatUseNum = this.$store.getters.getChatUseNum;

    // this.$store.watch(
    //   (state) => state.userMsg.RobotCreateNum, () => {
    //     this.RobotCreateNum = this.$store.getters.getRobotCreateNum;
    //   }, {
    //     deep: true
    //   }
    // );
    // this.RobotCreateNum = this.$store.getters.getRobotCreateNum;

    // this.$store.watch(
    //   (state) => state.userMsg.ChatUseNum, () => {
    //     this.ChatUseNum = this.$store.getters.getChatUseNum;
    //   }, {
    //     deep: true
    //   }
    // );
    // this.ChatUseNum = this.$store.getters.getChatUseNum;

    // this.$store.watch(
    //   (state) => state.userMsg.DocAddNum, () => {
    //     this.DocAddNum = this.$store.getters.getDocAddNum;
    //   }, {
    //     deep: true
    //   }
    // );
    // this.DocAddNum = this.$store.getters.getDocAddNum;
  },

  mounted() {

  },

  methods: {
    handleLogout() {
      removeLocalStorage('PromptBoomUser');
      removeLocalStorage('PromptBoomToken');

      this.$store.commit('HandleisLogin', false);
      this.$store.commit('HandleuserID', '');
      this.$store.commit('Handletoken', '');
      this.$store.commit('HandleuserName', '');
      this.$store.commit('HandleIsProUser', false);
      this.$store.commit('HandleRobotLinks', [{
        'botName': 'Default',
        'botDescription': "I am PowerChat, let's unlock a better future with AI together",
        'botID': 'default',
        'photoUrl': require("@/assets/P.png"),
      }]);
      // this.$store.commit('HandleRobotLinks', []);
      // this.$store.commit('HandlePowerChatRobotLinks', {});
      // this.$store.commit('HandleExploreRobotLinks', {});

      if (this.$router.currentRoute.path !== '/Login') {
        this.$router.push('/Login');
      }
    },
    handlePricing() {
      if (this.$router.currentRoute.path !== '/Pricing') {
        this.$router.push('/Pricing');
      }
    }
  }

};
</script>

<style scoped>

</style>