<template>
  <div>
    <!-- <YA_ScrollComponent :scrollHeight="55"> -->
      <PowerCodeExplainerMain ref="mainHeight" />

      <div>
        <PowerCodeExplainerContent />
      </div>

      <v-footer inset padless>
        <YA_footer />
      </v-footer>
    <!-- </YA_ScrollComponent> -->
  </div>
</template>

<script>
import YA_footer from '@/components/WebBar/YA_footer.vue';
import YA_ScrollComponent from '@/components/WebBar/YA_ScrollComponent.vue';
import PowerCodeExplainerMain from '@/components/PowerCodeExplainer/PowerCodeExplainerMain.vue';
import PowerCodeExplainerContent from '@/components/PowerCodeExplainer/PowerCodeExplainerContent.vue';


export default {
  
  name: 'PowerCodeExplainerView',
  components: {
    YA_footer,
    // YA_ScrollComponent,
    PowerCodeExplainerMain,
    PowerCodeExplainerContent,
  },
  data() {
    return {

    }
  },
  created() {
    this.$store.watch(
      (state) => state.powerCodeExplainerMsg.powerCodeExplainerMainHeight, () => {
        if (this.$store.getters.getpowerCodeExplainerMainHeight) {
          window.scrollTo({
            top: this.$refs.mainHeight.$el.clientHeight,
            behavior: 'smooth',
          });
          this.$store.commit('HandlepowerCodeExplainerMainHeight', false);
        }
      }, {
        deep: true
      }
    );
  },
  mounted() {

  },

  methods: {

  }

};
</script>

<style scoped>
</style>