<template>
  <div>
    <div>
      <YA_TelegramContent1 />
    </div>
    <div>
      <YA_TelegramContent2 />
    </div>
  </div>
</template>

<script>
import YA_TelegramContent1 from '@/components/PowerTelegram/YA_TelegramContent1.vue';
import YA_TelegramContent2 from '@/components/PowerTelegram/YA_TelegramContent2.vue';

export default {
  name: 'PowerTelegramMain',
  components: {
    YA_TelegramContent1,
    YA_TelegramContent2,
  },
  data() {
    return {
    }
  },
  mounted() {

  },

  methods: {

  }

};
</script>

<style scoped>
</style>