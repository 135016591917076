<template>
  <v-app>
    <YA_Snackbar ref="YA_SnackbarRef" />

    <v-app-bar app elevation="0" short outlined clipped-left>
      <div class="d-flex align-center justify-space-between" style="width: 100%;">
        <div class="d-flex">
          <YA_barTag />

          <YA_Logo />
        </div>
        <div class="d-flex align-center text-h6 text-md-h6 text-lg-h6 text-xl-h6 font-weight-bold">{{ funcName }}</div>
        <div class="d-flex align-center">
          <div v-if="!$vuetify.breakpoint.smAndDown">
            <v-btn v-if="!IsProUser" color="blue" rounded @click="handlebeProUser">
              <v-icon size="25" color="white" class="mr-1">mdi-check-decagram-outline</v-icon>
              <h4 class="text-capitalize white--text font-weight-black">Upgrade plan</h4>
            </v-btn>
            <v-btn v-else icon small class="mx-1">
              <v-icon size="30" color="amber accent-4">mdi-check-decagram</v-icon>
            </v-btn>

          </div>
          <YA_User v-if="isLogin" />
          <v-tooltip bottom v-else>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="grey-darken" v-bind="attrs" v-on="on" small class="mx-3" icon @click="handleLogin">
                <v-icon size="35">mdi-account-circle-outline</v-icon>
              </v-btn>
              <!-- <v-btn color="indigo" v-bind="attrs" v-on="on" rounded @click="handlebeProUser">
                <h4 class="text-capitalize white--text font-weight-black">Get Started</h4>
              </v-btn> -->
            </template>
            <span>Login</span>
          </v-tooltip>
        </div>
      </div>
    </v-app-bar>

    <v-main>
      <keep-alive>
        <router-view />
      </keep-alive>
    </v-main>
  </v-app>
</template>

<script>
import YA_Logo from '@/components/WebBar/YA_Logo.vue';
import YA_barTag from '@/components/WebBar/YA_barTag.vue';
// import YA_Navigation from '@/components/WebBar/YA_Navigation.vue';
import YA_User from '@/components/WebBar/YA_User.vue';
import { getLocalStorage, setLocalStorage, removeLocalStorage } from '@/utils/SystemUtils/localStorage.js'
import { requestPost } from '@/utils/SystemUtils/request.js';
import { handleNotLoginLimit, getNotLoginLimit } from '@/utils/SystemUtils/userNotLogin.js';
import YA_Snackbar from '@/components/WebBar/YA_Snackbar.vue';


export default {
  name:'AppView',
  components: {
    YA_Logo,
    YA_barTag,
    // YA_Navigation,
    YA_User,
    YA_Snackbar,
  },
  data() {
    return {
      LeftBarTag: false,

      isLogin: false,
      // PageLogin: false,
      IsProUser: false,

      funcName: '',
    }
  },
  created() {
  },
  beforeDestroy() {
  },
  mounted() {
    // document.documentElement.style.overflowY = "hidden";
    handleNotLoginLimit()
    this.$store.commit('HandleNotLoginChatNum', getNotLoginLimit());

    this.$store.watch(
      (state) => state.userMsg.isLogin, () => {
        this.isLogin = this.$store.getters.getisLogin;
        if (this.isLogin) {
          this.handleGetUserMsg();
          // this.handleGetExploreBot();
        }
      }, {
        deep: true
      }
    );
    this.isLogin = this.$store.getters.getisLogin;

    this.$store.watch(
      (state) => state.systemMsg.funcName, () => {
        this.funcName = this.$store.getters.getfuncName;
      }, {
        deep: true
      }
    );
    this.funcName = this.$store.getters.getfuncName;

    this.$store.watch(
      (state) => state.userMsg.IsProUser, () => {
        this.IsProUser = this.$store.getters.getIsProUser;
      }, {
        deep: true
      }
    );
    this.IsProUser = this.$store.getters.getIsProUser;

    if (getLocalStorage('PromptBoomUser') && getLocalStorage('PromptBoomToken')) {
      this.$store.commit('HandleuserID', getLocalStorage('PromptBoomUser'));
      this.$store.commit('Handletoken', getLocalStorage('PromptBoomToken'));
      this.$store.commit('HandleisLogin', true);
    }
  },
  computed: {

  },
  methods: {
    handleLogin() {
      let url='';
      if (!this.isLogin) {
        url = '/Login'
      } else {
        url = '/chat'
      }
      if (this.$route.path !== url) {
        this.$router.push({ path: url});
      }
    },
    handlebeProUser() {
      if (this.$route.path !== '/Pricing') {
        this.$router.push({ path: '/Pricing'});
      }
    },
    async handleGetUserMsg() {
      let request_header = {
        email: getLocalStorage('PromptBoomUser'),
        token: getLocalStorage('PromptBoomToken')
      };
      let request_json = {};

      let request_url = '/requestGetUserMsg';
      const res = await requestPost(request_header, request_json, request_url);
      // console.log('requestGetUserMsg', res)

      if (res.statusCode == 1) {
        this.$store.commit('HandleuserName', res.data[0].userName);
        let isProUser=false
        const subscribeTime=new Date(res.data[0].subscribeTime*1000)

        const now=new Date()
        const oneMonthAgo=new Date();
        oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
        if (subscribeTime >= oneMonthAgo) {
          isProUser=true
        } else {
          isProUser=false
        }

        this.$store.commit('HandleIsProUser', isProUser);
        // this.IsProUser = isProUser;

        this.$store.commit('HandleChatUseNum', res.data[0].todayCreditBalance);
        // this.$store.commit('HandleRobotCreateNum', res.data[0].todayBotBalance);
        this.$store.commit('HandleDocAddNum', res.data[0].todayDocBalance);

        // this.handleGetAllBot();
        // this.handleGetAllDoc();

      } else {
        this.$refs.YA_SnackbarRef.handleSnackbar('Failed to retrieve user information:' + res.statusInfo);

      }
    },
  },
}
</script>

