<template>
  <div>
    <PowerChatMain />
    <!-- <div class="mt-10">
      <PowerChatContent />
    </div>
    <v-footer padless>
      <YA_footer />
    </v-footer> -->
  </div>
</template>

<script>
import PowerChatMain from "@/components/PowerChat/PowerChatMain.vue";
// import PowerChatContent from "@/components/PowerChat/PowerChatContent.vue";
// import YA_footer from "@/components/WebBar/YA_footer.vue";

export default {
  name: 'PowerChatView',
  components: {
    PowerChatMain,
    // PowerChatContent,
    // YA_footer,
  },
  data() {
    return {

    }
  },
  mounted() {

  },

  methods: {

  }

};
</script>

<style scoped>
.my-custom-font-title {
  font-family: 'Vina Sans', sans-serif;
}
</style>