<template>
  <div>
    <v-card elevation="0" rounded="lg" height="60">
      <v-container class="d-flex justify-space-between">
        <v-row class="justify-center" no-gutters>
          <v-col cols="6" sm="6" md="6" lg="6" xl="6">
            <div style="max-width: 200px;">
              <v-select
                color="cyan"
                readonly
                class="font-weight-bold"
                v-model="inputLanguage"
                :menu-props="{ maxHeight: '300', offsetY: true }"
                :items="inputLanguageTags"
                label="From Language"
                dense
                outlined
              ></v-select>
            </div>
          </v-col>
          <v-col cols="6" sm="6" md="6" lg="6" xl="6" class="d-flex justify-end">
            <div style="max-width: 200px;">
              <v-select
                color="cyan"
                class="font-weight-bold"
                v-model="outputLanguage"
                :menu-props="{ maxHeight: '300', offsetY: true }"
                :items="outputLanguageTags"
                label="To Language"
                @change="handlePowerTranslateChange"
                dense
                outlined
              ></v-select>
            </div>
          </v-col>
        </v-row>
        
        
        <!-- <div class="mt-3">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="cyan darken-1" v-bind="attrs" v-on="on" class="mx-1 mt-n2" icon @click="handleExchange">
                <v-icon>mdi-swap-horizontal</v-icon>
              </v-btn>
            </template>
            <span>Exchange</span>
          </v-tooltip>
        </div> -->
        

      </v-container>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'PowerTranslateHead',
  data() {
    return {
      inputLanguage: 'Detect Language',
      outputLanguage: 'English',
      inputLanguageTags: [
        'Detect Language','Arabic', 'Cantonese', 'Chinese(Simplified)', 'Chinese(Traditional)', 'English', 'Filipino', 'French', 'German', 'Japanese','Hindi', 'Indonesian', 'Italian', 'Korean', 'Malay', 'Portuguese', 'Russian', 'Spanish', 'Thai', 'Vietnamese'],
      customMenuProps: { 
        maxHeight: '400', 
        offsetY: true ,
        opsitionY: 30,
        top: true
      }
    }
  },
  computed: {
    outputLanguageTags() {
      return this.inputLanguageTags.slice(1)
    },
  },
  mounted() {
    this.handlePowerTranslateChange()
  },

  methods: {
    // handleExchange() {
    // },
    handlePowerTranslateChange() {
      let powerTranslateHeadMsg = {
        'outputLanguage': this.outputLanguage
      }
      // console.log(powerTranslateHeadMsg)
      this.$store.commit('HandlepowerTranslateHeadMsg', powerTranslateHeadMsg);
    }
  }

};
</script>

<style scoped>

</style>