
<template>
  <div class="ma-12">
    <div>
      <v-img src="@/assets/pic_empty.png" max-height="150" contain></v-img>
    </div>
    <!-- <p class="grey--text mt-8 text-center text--darken-4 text-h6 text-sm-h5 text-md-h4 text-lg-h4 text-xl-h4 font-weight-black">Official Featured GPTs</p> -->
    <div class="d-flex justify-center">
      <v-btn color="grey lighten-4" class="mt-2" rounded @click="handleLogin">
        <h4 class="text-capitalize grey--text">Please Login</h4>
      </v-btn>
      <!-- <span class="grey--text text-center mt-4">Please Login</span> -->
    </div>

  </div>

</template>

<script>
export default {
  name: 'YA_webEmpty',
  data() {
    return {
    }
  },

  mounted() {

  },
  computed: {
  },

  methods: {
    handleLogin() {
      let url='';
      if (!this.isLogin) {
        url = '/Login'
      } else {
        url = '/chat'
      }
      if (this.$route.path !== url) {
        this.$router.push({ path: url});
      }
    },
  }

};
</script>

<style scoped>
</style>