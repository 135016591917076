<template>
  <div>
    <YA_Snackbar ref="YA_SnackbarRef" />

    <YA_leftNavBar />

    <!-- <vue-custom-scrollbar ref="PageScroll" class="scroll-area" :settings="settings"> -->
      <keep-alive>
        <router-view />
      </keep-alive>
    <!-- </vue-custom-scrollbar> -->
    
  </div>
</template>

<script>
import YA_leftNavBar from "@/components/YA_leftNavBar.vue";
import YA_Snackbar from '@/components/WebBar/YA_Snackbar.vue';


export default {
  name: 'HomeView',
  components: {
    YA_leftNavBar,
    YA_Snackbar,
  },
  data() {
    return {
      settings: {
        suppressScrollY: false,
        suppressScrollX: false,
        wheelPropagation: false
      },
    }
  },
  created() {
    this.$store.watch(
      (state) => state.systemMsg.PromptBoomSnackBar, () => {
        if (this.$store.getters.getPromptBoomSnackBar !== '') {
          this.$refs.YA_SnackbarRef.handleSnackbar(this.$store.getters.getPromptBoomSnackBar);
          this.$store.commit('HandlePromptBoomSnackBar', '');
        }
      }, {
        deep: true
      }
    );
  },
  beforeDestroy() {
  },
  mounted() {
  },

  methods: {
  }
};
</script>

<style scoped>
.scroll-area {
  position: relative;
  margin: auto;
}
</style>