<template>
  <div>
    <!-- <YA_ScrollComponent :scrollHeight="55"> -->
      <div class="mt-16 pb-8">
        <YA_HomeContact />
      </div>

      <v-footer padless>
        <YA_footer />
      </v-footer>
    <!-- </YA_ScrollComponent> -->
  </div>
</template>


<script>
import YA_HomeContact from '@/components/WebContact/YA_HomeContact.vue';
import YA_footer from '@/components/WebBar/YA_footer.vue';
import YA_ScrollComponent from '@/components/WebBar/YA_ScrollComponent.vue';

// import SocialBar from '@/components/SocialBar';

export default {
  name: 'ContactView',
  components: {
    // SocialBar,
    YA_HomeContact,
    YA_footer,
    // YA_ScrollComponent,
  }
}

</script>
