<template>
  <div>
    <v-container>
      <p class="blue-grey--text text--darken-4 mt-8 text-center text-h6 text-sm-h5 text-md-h4 text-lg-h4 text-xl-h4 font-weight-black">Try PromptBoom for free NOW</p>
      <v-row class="justify-center">
        <v-col sm="12" md="8" lg="8" xl="8" class="text-center text-body-2 text-sm-body-1 text-md-body-1 text-lg-body-1 text-xl-body-1">
          PromptBoom is your All-in-One AI Assistant, combining our most advanced AI models and a bundle of AI tools into a single subscription plan, which is more cost-effective than paying for each tool separately.🎉
      </v-col>
      </v-row>

    </v-container>
    <v-container class="d-flex justify-center">
      <v-badge bordered color="error" overlap content="It's free" class="mt-4" >
        <v-btn color="indigo" x-large @click="handleLogin">
          <h6 class="text-capitalize white--text text-body-1 text-sm-h6 text-md-h6 text-lg-h6 text-xl-h6 font-weight-black">Get Started</h6>
          <v-icon color="white" class="mx-2">
            mdi-arrow-right-bold
          </v-icon>
        </v-btn>
      </v-badge>
    </v-container>
    
  </div>
</template>

<script>
export default {
  name: 'YA_Content_GO',
  data() {
    return {
      isLogin: false,
    }
  },
  created() {
    this.$store.watch(
      (state) => state.userMsg.isLogin, () => {
        this.isLogin = this.$store.getters.getisLogin;
      }, {
        deep: true
      }
    );
    this.isLogin = this.$store.getters.getisLogin;

  },
  computed: {

  },
  mounted() {

  },

  methods: {
    handleLogin() {
      let url='';
      url = '/PowerChat';
      if (this.$route.path !== url) {
        this.$router.push({ path: url});
      }
    },
  }

};
</script>

<style scoped>
  .cardBack {
    background: linear-gradient(to top right, #F1FCFF, rgb(250,250,252));
  }
</style>