
<template>
  <div class="my-5">
    <v-row class="justify-center">
      <v-col cols="10" sm="10" md="10" lg="10" xl="10" class="d-flex justify-center flex-wrap">
        <v-container class="d-flex justify-center">
          <v-avatar size="84">
            <v-img alt="avatar" contain src="@/assets/P.png" />
          </v-avatar>
        </v-container>
        <div class="text-center">
          <p class="grey--text text--darken-2 text-h5 text-sm-h4 text-md-h3 text-lg-h3 text-xl-h3 font-weight-black">PromptBoom</p>
          <p class="mt-5 text-body-2 text-sm-body-1 text-md-body-1 text-lg-body-1 text-xl-body-1 font-weight-black">
            Your All-in-One AI Assistant
          </p>
        </div>
      </v-col>
    </v-row>
<!--     <v-container>
      <v-row class="justify-center">
        <v-col cols="12" sm="12" md="8" lg="8" xl="8">
            <v-img
              src="@/assets/HomePic/pic_about_promptboom_cutPowerFiles.png"
            ></v-img>
        </v-col>
      </v-row>
    </v-container> -->
  </div>
</template>

<script>
export default {
  name: 'YA_AboutTitle',
  data() {
    return {

    }
  },
  mounted() {

  },

  methods: {
  }

};
</script>

<style scoped>

</style>