<template>
  <div>
    <v-container class="mb-n8">
      <v-textarea
        class="mt-2"
        height="200"
        outlined
        clearable
        label="Tell me what to write..."
        v-model="PowerWriterRequire"
        maxlength="5000"
        color="yellow darken-4"
        no-resize
        counter
      ></v-textarea>
    </v-container>
    <v-container>
      <div v-for="(tagItem, index) in PowerWriterTag" :key="index" class="mb-3">
        <span class="yellow--text text--darken-3 text--body-2 font-weight-bold">
          <v-icon color="yellow darken-4" class="mt-n1">{{ tagItem.tagIcon }}</v-icon>
          {{ tagItem.tagName }}
        </span>
        <v-btn-toggle
          v-model="tagItem.tagNowSelect"
          mandatory
          dense
          color="yellow darken-4"
          group
          class="d-flex flex-wrap"
        >
          <v-btn 
            v-for="(tagSelectItem, tagSelectIndex) in tagItem.tagSelect" :key="tagSelectIndex"
            class="ma-1"
            small
            @click="handleLengthTagChoice()"
          >
            <p class="text-capitalize text-body-2 mt-4">{{ tagSelectItem }}</p>
          </v-btn>
        </v-btn-toggle>
      </div>
    </v-container>

    <v-container class="d-flex justify-end mt-n3">
      <v-btn class="mr-2" color="yellow darken-4" dark :loading="powerWriterLoading" @click="handlePowerWriter">
        <h4 class="text-capitalize white--text font-weight-black">Generate content</h4>
        <v-icon size="25" class="ml-1" color="white">mdi-arrow-right-bold-hexagon-outline</v-icon>
      </v-btn>
    </v-container>
  </div>
</template>

<script>
import YA_ScrollComponent from '@/components/WebBar/YA_ScrollComponent.vue';
import { fileLoadText } from '@/utils/PowerNomalUtils/LoadFileText_Nomal.js';
import { getDid } from '@/utils/SystemUtils/fingerprint.js'
import { getToken } from '@/utils/SystemUtils/token.js'
import { getBaseURL } from '@/utils/SystemUtils/url.js'
import { getCookie, setCookie, removeCookie } from '@/utils/SystemUtils/cookies.js'
import { getLocalStorage, setLocalStorage, removeLocalStorage } from '@/utils/SystemUtils/localStorage.js'
import { setNotLoginLimit } from '@/utils/SystemUtils/userNotLogin.js'
import { createHash } from 'crypto-browserify';

import {marked} from 'marked';
import { mangle } from "marked-mangle";
import { gfmHeadingId } from "marked-gfm-heading-id";
import markedLinkifyIt from "marked-linkify-it";
import markedKatex from "marked-katex-extension";
import 'katex/dist/katex.min.css';

import 'highlight.js/styles/monokai-sublime.css';

export default {
  name: 'PowerWriterLeft',
  components: {
    // YA_ScrollComponent,
  },
  data() {
    return {
      PowerWriterRequire: '',
      powerWriterHeadMsg: {},
      PowerWriterRes: '',

      PowerWriteTagSelected: {},
      PowerWriterTag: [
        {
          'tagIcon': 'mdi-text-long',
          'tagName': 'Length',
          'tagNowSelect': 0,
          'tagSelect': ['auto', 'short', 'medium', 'long',], 
        },
        {
          'tagIcon': 'mdi-file-document',
          'tagName': 'Format',
          'tagNowSelect': 0,
          'tagSelect': ['auto', 'email', 'blog', 'reviews', 'social media', 'slide','outline'], 
        },
        {
          'tagIcon': 'mdi-account-voice',
          'tagName': 'Tone',
          'tagNowSelect': 0,
          'tagSelect': ['auto', 'friendly', 'formal','casual', 'professional', 'humorous', 'interesting'], 
        },
      ],

      userID: '',
      token: '',
      userName: '',
      isLogin: false,
      ChatUseNum: 0,
      NotLoginChatNum: 0,

      isRunning: false,

      powerWriterLoading: false,
    }
  },
  created() {
    this.$store.watch(
      (state) => state.powerWriterMsg.powerWriterHeadMsg, () => {
        this.powerWriterHeadMsg = this.$store.getters.getpowerWriterHeadMsg;
        this.handleLengthTagChoice();
      }, {
        deep: true
      }
    );
    this.powerWriterHeadMsg = this.$store.getters.getpowerWriterHeadMsg;

    this.$store.watch(
      (state) => state.powerWriterMsg.powerWriterTryDemo, () => {
        if (this.$store.getters.getpowerWriterTryDemo) {
          this.PowerWriterRequire = "I want to invite friends to my birthday party.";
          this.PowerWriterTag[1].tagNowSelect = 1;
          this.PowerWriterTag[2].tagNowSelect = 1;
          this.handleLengthTagChoice();
          this.$nextTick(() => {
            this.handlePowerWriter();
            this.$store.commit('HandlepowerWriterTryDemo', false);
          })
          
        }
      }, {
        deep: true
      }
    );

    this.$store.watch(
      (state) => state.userMsg.isLogin, () => {
        this.isLogin = this.$store.getters.getisLogin;
        this.userID = this.$store.getters.getuserID;
        this.token = this.$store.getters.gettoken;
      }, {
        deep: true
      }
    );
    this.isLogin = this.$store.getters.getisLogin;
    this.userID = this.$store.getters.getuserID;
    this.token = this.$store.getters.gettoken;

    this.$store.watch(
      (state) => state.userMsg.ChatUseNum, () => {
        this.ChatUseNum = this.$store.getters.getChatUseNum;
      }, {
        deep: true
      }
    );
    this.ChatUseNum = this.$store.getters.getChatUseNum;

    this.$store.watch(
      (state) => state.userMsg.NotLoginChatNum, () => {
        this.NotLoginChatNum = this.$store.getters.getNotLoginChatNum;
      }, {
        deep: true
      }
    );
    this.NotLoginChatNum = this.$store.getters.getNotLoginChatNum;
  },
  mounted() {
    let did = getDid().then(res => {
      setCookie('did', res)
      setLocalStorage('did', res)
    });
    let lastSession=Date.now()
    setCookie('lastSession', lastSession)

    // for (let key in this.chatContent) {
    //   removeLocalStorage(key + 'chatlist')
    // }
    const localstoragekeys = Object.keys(localStorage);

    localstoragekeys.forEach(function(key) {
      if (key.endsWith("chatlist")) {
        removeLocalStorage(key);
      }
    });

    marked.use(mangle());
    marked.use(gfmHeadingId({prefix: "my-prefix-"}));
    marked.use(markedLinkifyIt());
    marked.use(markedKatex({throwOnError: false}));

    this.handleLengthTagChoice();
  },

  methods: {
    handleLengthTagChoice() {
      this.$nextTick(() => {
        this.PowerWriterTag.forEach((item) => {
          this.PowerWriteTagSelected[item.tagName] = item.tagSelect[item.tagNowSelect]
        })
        this.PowerWriteTagSelected = { ...this.PowerWriteTagSelected, ...this.powerWriterHeadMsg }
      })
    },
    handlePowerWriter() {
      this.PowerWriterRes = '';
      this.$store.commit('HandlepowerWriterRes', '');

      if (this.isLogin) {
        if (this.ChatUseNum > 0) {
          if (this.PowerWriterRequire.length > 5000) {
            this.$store.commit('HandlePromptBoomSnackBar', 'The text is too long.')
          } else {
            if (this.PowerWriterRequire.length > 0) {
              this.handlePowerChat();
            }
          }
        } else {
          this.$store.commit('HandlePromptBoomSnackBar', 'You have 0 free message left today')
        }
      } else if (this.NotLoginChatNum > 0) {
        if (this.PowerWriterRequire.length > 5000) {
          this.$store.commit('HandlePromptBoomSnackBar', 'The text is too long.')
        } else {
          if (this.PowerWriterRequire.length > 0) {
            this.handlePowerChat();
          }
        }
      } else {
        this.$store.commit('HandlePromptBoomSnackBar', 'Please Login')
      }
    },
    async handlePowerChat() {
      this.powerWriterLoading = true;
      this.isRunning = true;

      let response;
      try {
        response = await this.requestPowerChat(this.PowerWriterRequire)
      } catch (error) {
        this.try_error(error)
      } finally {
        this.try_finally(response)
      }
    },
    async requestPowerChat(question) {
      let chatList = []

      let lastSession=getCookie('lastSession')
      let currentSession=Date.now()
      if((currentSession-lastSession)>1000*60*20){
        setCookie('lastSession', currentSession)
      }

      chatList.push({
        "role": "user",
        "content": question
      })

      const t = Date.now()
      const token=getToken()
      const r = t + ":" + "question:" + token
      const sign = createHash('sha256').update(r).digest('hex')

      let request_json = {
        'did': getCookie('did'),
        'chatList': chatList,
        'botID': 'PowerWriter',
        // 'botID': 'default',
        'requireMsg': this.PowerWriteTagSelected,
        'special': {
          'referer':document.referrer||'no-referer',
          'path':location.href
        }
      };

      let raw_requst_json = {
        'data': this.utoa(JSON.stringify(request_json))
      };

      let baseURL=getBaseURL()
      let requestUrl =`${baseURL}/requestPowerChat`

      const response = await fetch(requestUrl, {
        method: 'POST',
        headers:{
          email: this.userID,
          token: this.token,
          isProUser: this.$store.getters.getIsProUser
        },
        body: JSON.stringify(raw_requst_json)
      });
      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8")
      while (this.isRunning) {
        const { value, done } = await reader.read();
        if (done) {
          break;
        }
        let decodedValue = decoder.decode(value);
        this.PowerWriterRes+=decodedValue;
        this.$store.commit('HandlepowerWriterRes', this.PowerWriterRes);
      }
      return response
    },
    try_error(error) {
      this.PowerWriterRes += "Network error[2001],you can try again or notify us by sending an email to hello@promptboom.com. Thank you";
      this.$store.commit('HandlepowerWriterRes', this.PowerWriterRes);
    },
    try_finally(response) {
      if (response.status === 200) {
        this.$store.commit('HandleChatUseNum', this.ChatUseNum - 1);
        this.$store.commit('HandleNotLoginChatNum', this.NotLoginChatNum - 1);
        setNotLoginLimit();
      }

      this.powerWriterLoading = false;
      this.isRunning = false;

      let tempAnswer = this.PowerWriterRes;
      if (tempAnswer.length == 0) {
        this.PowerWriterRes = "Network error[2001],you can try again or notify us by sending an email to hello@promptboom.com. Thank you";
        this.$store.commit('HandlepowerWriterRes', this.PowerWriterRes);
      }
    },
    utoa(data) {
      return btoa(unescape(encodeURIComponent(data)));
    },
    atou(b64) {
      return decodeURIComponent(escape(atob(b64)));
    },
  }

};
</script>

<style scoped>
</style>