<template>
  <div>
    <!-- <YA_ScrollComponent :scrollHeight="55"> -->

      <PowerTelegramMain />
      
      <v-footer padless>
        <YA_footer />
      </v-footer>
    <!-- </YA_ScrollComponent> -->

  </div>
</template>

<script>
import PowerTelegramMain from '@/components/PowerTelegram/PowerTelegramMain.vue';
import YA_ScrollComponent from '@/components/WebBar/YA_ScrollComponent.vue';
import YA_footer from '@/components/WebBar/YA_footer.vue';

export default {
  name: 'PowerTelegramView',
  components: {
    PowerTelegramMain,
    // YA_ScrollComponent,
    YA_footer,
  },
  data() {
    return {

    }
  },
  mounted() {

  },

  methods: {

  }

};
</script>

<style scoped>

</style>