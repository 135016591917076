<template>
  <div class="pt-8 pb-16" style="background-color: rgb(231,231,231);">
    <YA_Snackbar ref="YA_SnackbarRef" />

    <v-row class="justify-center">
      <v-col cols="11" sm="11" md="11" lg="11" xl="11" class="d-flex justify-center">
        <v-card width="1200" rounded="lg">
          <PowerSummarizerHead />

          <v-divider></v-divider>

          <v-row class="justify-center" no-gutters>
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <PowerSummarizerLeft />
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <PowerSummarizerRight />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    
  </div>
</template>

<script>
import YA_Snackbar from '@/components/WebBar/YA_Snackbar.vue';
import PowerSummarizerLeft from '@/components/PowerSummarizer/PowerSummarizerLeft.vue';
import PowerSummarizerRight from '@/components/PowerSummarizer/PowerSummarizerRight.vue';
import PowerSummarizerHead from '@/components/PowerSummarizer/PowerSummarizerHead.vue';

import { requestPost } from '@/utils/SystemUtils/request.js';
import { getLocalStorage, setLocalStorage, removeLocalStorage } from '@/utils/SystemUtils/localStorage.js'


export default {
  name: 'PowerWriterMain',
  components: {
    YA_Snackbar,
    PowerSummarizerLeft,
    PowerSummarizerRight,
    PowerSummarizerHead,
  },
  data() {
    return {
      userID: '',
      token: '',
      userName: '',
      isLogin: false,
    }
  },
  created() {
    this.$store.watch(
      (state) => state.userMsg.isLogin, () => {
        this.isLogin = this.$store.getters.getisLogin;
        this.userID = this.$store.getters.getuserID;
        this.token = this.$store.getters.gettoken;
      }, {
        deep: true
      }
    );
    this.isLogin = this.$store.getters.getisLogin;
    this.userID = this.$store.getters.getuserID;
    this.token = this.$store.getters.gettoken;

  },
  mounted() {
    
  },

  methods: {
  }

};
</script>

<style scoped>

</style>