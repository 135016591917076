<template>
  <div>
    <YA_ScrollComponent :scrollHeight="scrollHeight">
      <v-container>
        <p class="blue-grey--text mt-8 text-center text--darken-4 text-h6 text-sm-h5 text-md-h4 text-lg-h4 text-xl-h4 font-weight-black">Official Featured Bots</p>
        <p class="blue-grey--text text-center mb-8">we have fine-tuned these bots with a lot of effort to achieve the best user experience</p>
        
        <YA_webEmpty v-if="!isLogin" class="mt-16 pt-16"/>

        <div v-else>
          <div v-if="ExploreLoading" class="d-flex justify-center mt-16">
            <v-progress-circular
              indeterminate
              size="64"
              color="blue"
            ></v-progress-circular>
          </div>
          <div v-else>
            <v-row class="justify-center">
              <v-col cols="10" sm="10" md="10" lg="10" xl="8">
                <v-list elevation="2" two-line>
                  <template v-for="(item, index) in ExplorePageItems[page-1]">
                    <v-list-item :key="item.botID">
                      <v-list-item-avatar size="40">
                        <v-img :src=item.photoUrl></v-img>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title>{{ item.botName }}</v-list-item-title>

                        <v-list-item-subtitle>{{ item.botDescription }}</v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-list-item-action-text>
                          <v-btn
                            :loading="item.ULoading"
                            class="mx-2"
                            icon
                            color="teal"
                            @click="handleExploreChoice(item.botID)"
                          >
                            <v-icon dark>
                              mdi-plus
                            </v-icon>
                          </v-btn>
                        </v-list-item-action-text>

                      </v-list-item-action>
                    </v-list-item>

                    <v-divider
                      v-if="index < ExploreItems.length - 1"
                      :key="index"
                    ></v-divider>
                  </template>
                </v-list>
              </v-col>
            </v-row>
      <!--       <div class="text-center py-6">
              <v-pagination
                v-model="page"
                :length="pageLength"
                :total-visible="pageTotal"
              ></v-pagination>
            </div> -->
          </div>
        </div>
      </v-container>
    </YA_ScrollComponent>


  </div>
</template>

<script>
import { nextTick } from 'vue';
import YA_Snackbar from '@/components/WebBar/YA_Snackbar.vue';
import YA_webEmpty from '@/components/WebBar/YA_webEmpty.vue';
import YA_ScrollComponent from '@/components/WebBar/YA_ScrollComponent.vue';
import { requestPost } from '@/utils/SystemUtils/request.js';

export default {
  name: 'YA_ExploreBot',
  components: {
    YA_ScrollComponent,
    YA_webEmpty,
  },
  data() {
    return {
      page: 1,
      pageTotal: 100,
      ExploreLoading: false,
      ExploreSelected: '',
      ExploreItems:[
        // {'title': "Midjourney", 'subtitle': "Midjourney photo promper,Midjourney photo promper,Midjourney photo promper,Midjourney photo promper,Midjourney photo promper,Midjourney photo promper,Midjourney photo promper,Midjourney photo promper", 'photoUrl': "https://lh3.googleusercontent.com/a/AAcHTtfRTow3d3K0Mk0ydNLF_o_u3pFv4LSxPP0PwAEHZOPmJQ=s648-c-no"},
      ],
      isLogin: false,
      userID: '',
      token: '',
      ExploreRobotCreateLoading: false,
    }
  },
  created() {
    this.$store.watch(
      (state) => state.userMsg.isLogin, () => {
        this.isLogin = this.$store.getters.getisLogin;
        this.userID = this.$store.getters.getuserID;
        this.token = this.$store.getters.gettoken;
      }, {
        deep: true
      }
    );
    this.isLogin = this.$store.getters.getisLogin;
    this.userID = this.$store.getters.getuserID;
    this.token = this.$store.getters.gettoken;


    this.$store.watch(
      (state) => state.powerChatMsg.ExploreRobotLinks, () => {
        this.ExploreItems = this.$store.getters.getExploreRobotLinks;

      }, {
        deep: true
      }
    );
    this.ExploreItems = this.$store.getters.getExploreRobotLinks;

    this.$store.watch(
      (state) => state.powerChatMsg.ExploreLoading, () => {
        this.ExploreLoading = this.$store.getters.getExploreLoading;
      }, {
        deep: true
      }
    );
    this.ExploreLoading = this.$store.getters.getExploreLoading;

  },
  beforeDestroy() {
  },
  mounted() {
  },
  activated() {

  },
  computed: {
    pageLength() {
      return Math.ceil(this.ExploreItems.length / this.pageTotal);
    },
    ExplorePageItems() {
      const data = [];
      for (let i = 0; i < this.pageLength; i++) {
        const startIndex = i * this.pageTotal;
        const endIndex = startIndex + this.pageTotal;
        const newRow = this.ExploreItems.slice(startIndex, endIndex);
        data.push(newRow);
      }
      return data;
    },
    scrollHeight() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return 110;
      } else {
        return 60;
      }
    },
  },
  methods: {
    async handleExploreChoice(ID) {
      this.ExploreItems.forEach ((item)=>{
        if (item.botID === ID) {
          item.ULoading = true;
        }
      })

      let request_header = {
        email: this.userID,
        token: this.token
      };
      let request_json = {
        'botID': ID,
      };

      let request_url = '/requestAddExploreBot';
      const res = await requestPost(request_header, request_json, request_url);
      if (res.statusCode == 1) {
        this.$store.commit('HandlerefreshRobotLinks', true);
        // this.$store.commit('HandleRobotCreateNum', this.RobotCreateNum - 1);
        if (this.$router.currentRoute.path !== '/chat/PowerChat') {
          this.$router.push('/chat/PowerChat');
        }
        this.ExploreItems.forEach ((item)=>{
          if (item.botID === ID) {
            item.ULoading = false;
          }
        })
      } else {
        this.$store.commit('HandlePromptBoomSnackBar', 'Create Robot failed: ' + res.statusInfo)
        this.ExploreItems.forEach ((item)=>{
          if (item.botID === ID) {
            item.ULoading = false;
          }
        })
      }
    },
  }
};
</script>

<style scoped>

</style>