import Vue from 'vue';
import VueRouter from 'vue-router';
import store from "@/store/index"; 
import vuetify from '@/plugins/vuetify';

import HomeView from '@/views/HomeView.vue';
import PowerChatView from '@/views/PromptBoom/PowerChatView.vue';
// import PowerFileView from '@/views/PromptBoom/PowerFileView.vue';
import PowerWriterView from '@/views/PromptBoom/PowerWriterView.vue';
import PowerTelegramView from '@/views/PromptBoom/PowerTelegramView.vue';
import PowerSummarizerView from '@/views/PromptBoom/PowerSummarizerView.vue';
import PowerRewriteView from '@/views/PromptBoom/PowerRewriteView.vue';
import PowerImitateView from '@/views/PromptBoom/PowerImitateView.vue';
import PowerTranslateView from '@/views/PromptBoom/PowerTranslateView.vue';
import PowerGrammarFixView from '@/views/PromptBoom/PowerGrammarFixView.vue';
import PowerCodeExplainerView from '@/views/PromptBoom/PowerCodeExplainerView.vue';
import PowerFewShotView from '@/views/PromptBoom/PowerFewShotView.vue';
// import PowerPictureView from '@/views/PromptBoom/PowerPictureView.vue';

import YA_Login from '@/components/WebBar/YA_Login.vue';
import PricingView from '@/views/PricingView.vue';
import AboutView from '@/views/AboutView.vue';
import ContactView from '@/views/ContactView.vue';

import PowerChatTalk from "@/components/PowerChat/PowerChatTalk.vue";
import YA_ExploreBot from "@/components/PowerChat/YA_ExploreBot.vue";
import YA_CreateBot from "@/components/PowerChat/YA_CreateBot.vue";



Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
    redirect: '/PowerChat',
    meta:{
      'title':''
    },
    children: [
      {
        path: 'PowerChat',
        name: 'PowerChat',
        component: PowerChatView,
        redirect: '/PowerChat/PowerChatTalk',
        meta:{
          'title':'PowerChat - Fast, Helpful AI Chat'
        },
        children: [
          {
            path: 'PowerChatTalk',
            name: 'PowerChatTalk',
            component: PowerChatTalk,
            meta:{
              'title':'PowerChat - Fast, Helpful AI Chat'
            },
          },
          {
            path: 'ExploreBot',
            name: 'ExploreBot',
            component: YA_ExploreBot,
            meta:{
              'title':'ExploreBot - Fast, Helpful AI Chat'
            },
          },
          {
            path: 'CreateBot',
            name: 'CreateBot',
            component: YA_CreateBot,
            meta:{
              'title':'CreateBot - Fast, Helpful AI Chat'
            },
          },
        ]
      },
      // {
      //   path: 'PowerFile',
      //   name: 'PowerFile',
      //   component: PowerFileView,
      //   meta:{
      //     'title':'PowerFile - Chat with any PDF!'
      //   },
      // },
      {
        path: 'PowerWriter',
        name: 'PowerWriter',
        component: PowerWriterView,
        meta:{
          'title':'PowerWriter - Write better, faster, and smarter'
        },
      },
      {
        path: 'PowerTelegram',
        name: 'PowerTelegram',
        component: PowerTelegramView,
        meta:{
          'title':'PowerTelegram - PromptBoom'
        },
      },
      {
        path: 'PowerSummarizer',
        name: 'PowerSummarizer',
        component: PowerSummarizerView,
        meta:{
          'title':'PowerSummarizer - Summarize any text and file with AI!'
        },
      },
      {
        path: 'PowerRewrite',
        name: 'PowerRewrite',
        component: PowerRewriteView,
        meta:{
          'title':'PowerRewrite - AI-powered paraphrasing tool'
        },
      },
      {
        path: 'PowerImitate',
        name: 'PowerImitate',
        component: PowerImitateView,
        meta:{
          'title':'PowerImitate - AI-powered paraphrasing tool'
        },
      },
      {
        path: 'PowerTranslate',
        name: 'PowerTranslate',
        component: PowerTranslateView,
        meta:{
          'title':'PowerTranslate - Translate texts & full document files instantly'
        },
      },
      {
        path: 'PowerGrammarFix',
        name: 'PowerGrammarFix',
        component: PowerGrammarFixView,
        meta:{
          'title':'PowerGrammarFix - Online AI grammar check.'
        },
      },
      {
        path: 'PowerCodeExplainer',
        name: 'PowerCodeExplainer',
        component: PowerCodeExplainerView,
        meta:{
          'title':'PowerCodeExplainer - AI that reads and explains code in understandable ...'
        },
      },
      {
        path: 'PowerFewShot',
        name: 'PowerFewShot',
        component: PowerFewShotView,
        meta:{
          'title':'PowerFewShot - Few-shot learning AI tool'
        },
      },
      // {
      //   path: 'PowerPicture',
      //   name: 'PowerPicture',
      //   component: PowerPictureView,
      //   meta:{
      //     'title':'PowerPicture - Few-shot learning AI tool'
      //   },
      // },
      {
        path: 'Login',
        name: 'Login',
        component: YA_Login,
        meta:{
          'title':'Login - PromptBoom,The All-in-One AI Toolkit'
        },
      },
      {
        path: 'Pricing',
        name: 'Pricing',
        component: PricingView,
        meta:{
          'title':'Pricing - PromptBoom,The All-in-One AI Toolkit'
        },
      },
      {
        path: 'About',
        name: 'About',
        component: AboutView,
        meta:{
          'title':'About - PromptBoom,The All-in-One AI Toolkit'
        },
      },
      {
        path: 'Contact',
        name: 'Contact',
        component: ContactView,
        meta:{
          'title':'Contact - PromptBoom,The All-in-One AI Toolkit'
        },
      },
      // {
      //   path: 'ExploreRob',
      //   name: 'ExploreRob',
      //   component: ExploreRob,
      //   meta:{
      //     'title':'Chat - PowerChat'
      //   },
      // },
      // {
      //   path: 'PowerChat',
      //   name: 'PowerChat',
      //   component: PowerChat,
      //   meta:{
      //     'title':'Chat - PowerChat'
      //   },
      // },
      // {
      //   path: 'DocChat',
      //   name: 'DocChat',
      //   component: DocChat,
      //   meta:{
      //     'title':'Chat - DocChat'
      //   },
      // },
    ]
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   component: AboutView,
  //   meta:{
  //     'title':'About - PowerChat'
  //   }
  // },
  // {
  //   path: '/contact',
  //   name: 'contact',
  //   component: ContactView,
  //   meta:{
  //     'title':'Contact - PowerChat'
  //   }
  // },
  // {
  //   path: '/internal',
  //   name: 'internal',
  //   component: InternalView,
  //   meta:{
  //     'title':'internal - PowerChat'
  //   }
  // },
  // {
  //   path: '/chat',
  //   name: 'Chat',
  //   component: ChatView,
  //   redirect: '/chat/PowerChat',
  //   meta:{
  //     'title':'Chat - PowerChat'
  //   },
  //   children: [
  //     {
  //       path: 'CreateRob',
  //       name: 'CreateRob',
  //       component: CreateRob,
  //       meta:{
  //         'title':'Chat - PowerChat'
  //       },
  //     },
  //     {
  //       path: 'ExploreRob',
  //       name: 'ExploreRob',
  //       component: ExploreRob,
  //       meta:{
  //         'title':'Chat - PowerChat'
  //       },
  //     },
  //     {
  //       path: 'PowerChat',
  //       name: 'PowerChat',
  //       component: PowerChat,
  //       meta:{
  //         'title':'Chat - PowerChat'
  //       },
  //     },
  //     {
  //       path: 'DocChat',
  //       name: 'DocChat',
  //       component: DocChat,
  //       meta:{
  //         'title':'Chat - DocChat'
  //       },
  //     },
  //   ]
  // },
  // {
  //   path: '/pricing',
  //   name: 'Pricing',
  //   component: PricingView,
  //   meta:{
  //     'title':'Pricing - PowerChat'
  //   }
  // },
  // {
  //   path: '/Login',
  //   name: 'Login',
  //   component: YA_Login,
  //   meta:{
  //     'title':'Login - PowerChat'
  //   }
  // },
  // {
  //   path: '/EveryAPP',
  //   name: 'EveryAPP',
  //   component: EveryAPP,
  //   meta:{
  //     'title':'EveryAPP - PowerChat'
  //   }
  // },
  {
    path:"*",
    redirect:'/'
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to,from,next)=>{
  if(to.meta.title){
    document.title=to.meta.title
  }
  store.commit('HandlenowRouter', to.matched[1].path.slice(1));
  window.scrollTo({
    top: 0,
    behavior: 'auto',
  });
  if (to.path.startsWith('/PowerChat') || to.path === '/PowerFile') {
    if (!vuetify.framework.breakpoint.smAndDown) {
      document.documentElement.style.overflowY = "hidden";
    } else {
      document.documentElement.style.overflowY = "auto";
    }
  } else {
    document.documentElement.style.overflowY = "auto";
  }
  if (to.path === '/Login' || to.path === '/Pricing') {
    store.commit('HandlefuncName', '');
  } else {
    store.commit('HandlefuncName', to.matched[1].path.slice(1));
  }
  next()
});

export default router;
