<template>
  <div>
    <v-container>
      <v-textarea
        class="mt-2 mb-n7"
        filled
        v-model="powerWriterRes"
        counter
        no-resize
        color="yellow darken-4"
        height="410"
        hide-details="auto"
      ></v-textarea>
    </v-container>
    <v-container class="d-flex mt-6 justify-end">
      <!-- <div class="ml-2">
        <span class="deep-purple--text text--darken-3 font-weight-bold" v-if="modesChoice">
          {{ answerSentenseLength }}
          sentense<span v-if="answerSentenseLength > 1">s</span>
        </span>
        <span class="deep-purple--text text--darken-3 font-weight-bold" v-else>
          {{ answerPointLength }}
          point<span v-if="answerPointLength > 1">s</span>
        </span>
      </div> -->
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="yellow darken-4" v-bind="attrs" v-on="on" class="mx-1 mt-n2" icon @click="handleCopy">
            <v-icon>mdi-content-copy</v-icon>
          </v-btn>
        </template>
        <span>Copy</span>
      </v-tooltip>
    </v-container>
  </div>
</template>

<script>
import {marked} from 'marked';
import DOMPurify from 'dompurify';
export default {
  name: 'PowerWriterRight',
  data() {
    return {
      powerWriterRes: '',
    }
  },
  created() {
    this.$store.watch(
      (state) => state.powerWriterMsg.powerWriterRes, () => {
        this.powerWriterRes = this.$store.getters.getpowerWriterRes;
      }, {
        deep: true
      }
    );
    this.powerWriterRes = this.$store.getters.getpowerWriterRes;
  },
  mounted() {
  },

  methods: {
    htmlfy(answer) {
      // console.log(DOMPurify.sanitize(marked.parse(answer)))
      return DOMPurify.sanitize(marked.parse(answer));      
    },
    handleCopy() {
      const anwser=this.htmlfy(this.powerWriterRes)
      const temp = document.createElement('div');
      temp.innerHTML = anwser;
      document.body.appendChild(temp);
      const range = document.createRange();
      range.selectNode(temp);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand('copy');
      window.getSelection().removeAllRanges();
      document.body.removeChild(temp);
      this.$store.commit('HandlePromptBoomSnackBar', 'Copied')
    }
  }

};
</script>

<style scoped>
</style>