
<template>
  <v-container class="d-flex align-center">
    <router-link to="/" class='text-decoration-none' v-if="$vuetify.breakpoint.smAndDown">
      <v-img alt="PromptBoom Logo" class="shrink mr-2" contain src="@/assets/P.png" transition="scale-transition" width="50" />
    </router-link>
    <router-link to="/" class='text-decoration-none d-flex align-center' v-else>
      <v-img alt="PromptBoom Logo" class="shrink mr-2" contain src="@/assets/P.png" transition="scale-transition" width="50" />
      <span class="my-custom-title">Prompt</span>
      <!-- <span class="font-weight-black text-h6 my-custom-font-title ml-1" style="color: rgb(233,79,63);">B</span>
      <span class="font-weight-black text-h6 my-custom-font-title" style="color: rgb(244,157,18);">o</span>
      <span class="font-weight-black text-h6 my-custom-font-title" style="color: rgb(141,67,174);">o</span>
      <span class="font-weight-black text-h6 my-custom-font-title" style="color: rgb(38,175,96);">m</span> -->
      <span class="font-weight-black black--text text-h6 my-custom-font-title">Boom</span>
    </router-link>
  </v-container>

</template>

<script>
export default {
  name: 'YA_Logo',
  data() {
    return {

    }
  },

  props: {
  },

  mounted() {

  },

  methods: {

  }

};
</script>

<style scoped>
.my-custom-title {
  font-family: 'Pacifico', cursive;
  font-size: 20px;
  color: rgb(51,153,219);
}
</style>