<template>
  <div>
    <v-card elevation="0" rounded="lg">
      <v-container class="d-flex justify-space-between flex-wrap">
        <div class="d-flex flex-wrap justify-center">
          <span class="green--text text--darken-2 text-body-1 font-weight-bold mt-1">
            <v-icon color="green darken-2" class="mt-n1">mdi-collage</v-icon>
            Modes:
          </span>
          <div style="width: 200px;" class="ml-4 mt-n1 mb-n4 mb-xl-n8 mb-lg-n8 mb-md-n8">
            <v-select
              color="green"
              class="font-weight-bold"
              v-model="modesTagsSelected"
              :menu-props="{ maxHeight: '300', offsetY: true }"
              :items="modesTags"
              label="Choose Modes"
              @change="handlePowerGrammarFixChange"
              dense
              outlined
            ></v-select>
          </div>
          <!-- <div class="d-flex mt-n4 ml-10 align-center">
            <span class="green--text text--darken-2 text-body-1 font-weight-bold">
              Show Details
            </span>
            <v-switch
              v-model="modesSelected"
              @change="handlePowerGrammarFixChange"
              inset
              color="green"
              dense
              flat
              class="ml-3"
            ></v-switch>
            <span class="green--text text--darken-2 text-body-1 font-weight-bold">
              Fixed Directly
            </span>
          </div> -->
        </div>
        <div v-if="!$vuetify.breakpoint.smAndDown">

          <v-btn color="green" class="mr-2" small @click="handleTryDemo">
            <h4 class="text-capitalize white--text font-weight-black">Try demo</h4>
            <v-icon size="20" class="ml-1" color="white">mdi-emoticon-wink</v-icon>
          </v-btn>
        </div>
        <v-container v-else class="d-flex justify-center mt-1 mb-n3">
          <v-btn color="green" class="mr-2" small @click="handleTryDemo">
            <h4 class="text-capitalize white--text font-weight-black">Try demo</h4>
            <v-icon size="20" class="ml-1" color="white">mdi-emoticon-wink</v-icon>
          </v-btn>
        </v-container>

      </v-container>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'PowerGrammarFixHead',
  data() {
    return {
      modesTagsSelected: 'Show Details',
      modesTags: ['Show Details', 'Fixed Directly'],
    }
  },
  mounted() {
    this.handlePowerGrammarFixChange();
  },

  methods: {
    handlePowerGrammarFixChange() {
      let powerGrammarFixHeadMsg = { 'modes': '' }
      if (this.modesTagsSelected == 'Show Details') {
        powerGrammarFixHeadMsg.modes = 'Detail';
      } else if (this.modesTagsSelected == 'Fixed Directly') {
        powerGrammarFixHeadMsg.modes = 'Fixed';
      }
      this.$store.commit('HandlepowerGrammarFixHeadMsg', powerGrammarFixHeadMsg);
    },
    handleTryDemo() {
      this.modesSelected = false;
      this.handlePowerGrammarFixChange();
      this.$store.commit('HandlepowerGrammarFixTryDemo', true);
    }
  }
};
</script>

<style scoped>

</style>