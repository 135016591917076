<template>
  <div>
    <!-- <YA_ScrollComponent :scrollHeight="55"> -->
      <PowerSummarizerMain ref="mainHeight" />
      
      <div>
        <PowerSummarizerContent />
      </div>

      <v-footer inset padless>
        <YA_footer />
      </v-footer>
    <!-- </YA_ScrollComponent> -->
  </div>
</template>

<script>
import YA_footer from '@/components/WebBar/YA_footer.vue';
import PowerSummarizerMain from '@/components/PowerSummarizer/PowerSummarizerMain.vue';
import YA_ScrollComponent from '@/components/WebBar/YA_ScrollComponent.vue';
import PowerSummarizerContent from '@/components/PowerSummarizer/PowerSummarizerContent.vue';


export default {
  name: 'PowerSummarizerView',
  components: {
    YA_footer,
    // YA_ScrollComponent,
    PowerSummarizerMain,
    PowerSummarizerContent,
  },
  data() {
    return {

    }
  },
  created() {
    this.$store.watch(
      (state) => state.powerSummarizerMsg.powerSummarizerMainHeight, () => {
        if (this.$store.getters.getpowerSummarizerMainHeight) {
          window.scrollTo({
            top: this.$refs.mainHeight.$el.clientHeight,
            behavior: 'smooth',
          });
          this.$store.commit('HandlepowerSummarizerMainHeight', false);
        }
      }, {
        deep: true
      }
    );
  },
  mounted() {

  },

  methods: {

  }

};
</script>

<style scoped>
</style>