<template>
  <div>
    <!-- <YA_ScrollComponent :scrollHeight="55"> -->
      <PowerRewriteMain ref="mainHeight" />

      <div>
        <PowerRewriteContent />
      </div>

      <v-footer inset padless>
        <YA_footer />
      </v-footer>
    <!-- </YA_ScrollComponent> -->
  </div>
</template>

<script>
import YA_footer from '@/components/WebBar/YA_footer.vue';
import YA_ScrollComponent from '@/components/WebBar/YA_ScrollComponent.vue';
import PowerRewriteMain from '@/components/PowerRewrite/PowerRewriteMain.vue';
import PowerRewriteContent from '@/components/PowerRewrite/PowerRewriteContent.vue';

export default {
  
  name: 'PowerRewriteView',
  components: {
    YA_footer,
    // YA_ScrollComponent,
    PowerRewriteMain,
    PowerRewriteContent,
  },
  data() {
    return {

    }
  },
  created() {
    this.$store.watch(
      (state) => state.powerRewriteMsg.powerRewriteMainHeight, () => {
        if (this.$store.getters.getpowerRewriteMainHeight) {
          window.scrollTo({
            top: this.$refs.mainHeight.$el.clientHeight,
            behavior: 'smooth',
          });
          this.$store.commit('HandlepowerRewriteMainHeight', false);
        }
      }, {
        deep: true
      }
    );
  },
  mounted() {

  },

  methods: {

  }

};
</script>

<style scoped>
</style>