<template>
  <div class="pt-8 pb-16" style="background-color: rgb(231,231,231);">
    <YA_Snackbar ref="YA_SnackbarRef" />

    <v-row class="justify-center">
      <v-col cols="11" sm="11" md="11" lg="11" xl="11" class="d-flex justify-center">
        <v-card width="1200" rounded="lg">
          <PowerWriterHead />

          <v-divider></v-divider>

          <v-row no-gutters>
            <v-col cols="12" sm="12" md="7" lg="7" xl="7">
              <PowerWriterLeft />
            </v-col>
            <v-divider vertical v-if="!$vuetify.breakpoint.smAndDown"></v-divider>
            <v-col cols="12" sm="12" md="5" lg="5" xl="5">
              <PowerWriterRight />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    
  </div>
</template>

<script>
import YA_Snackbar from '@/components/WebBar/YA_Snackbar.vue';
import PowerWriterLeft from '@/components/PowerWriter/PowerWriterLeft.vue'
import PowerWriterRight from '@/components/PowerWriter/PowerWriterRight.vue'
import PowerWriterHead from '@/components/PowerWriter/PowerWriterHead.vue'

import { requestPost } from '@/utils/SystemUtils/request.js';
import { getLocalStorage, setLocalStorage, removeLocalStorage } from '@/utils/SystemUtils/localStorage.js'


export default {
  name: 'PowerWriterMain',
  components: {
    YA_Snackbar,
    PowerWriterLeft,
    PowerWriterRight,
    PowerWriterHead,
  },
  data() {
    return {
      userID: '',
      token: '',
      userName: '',
      isLogin: false,
    }
  },
  created() {
    this.$store.watch(
      (state) => state.userMsg.isLogin, () => {
        this.isLogin = this.$store.getters.getisLogin;
        this.userID = this.$store.getters.getuserID;
        this.token = this.$store.getters.gettoken;
      }, {
        deep: true
      }
    );
    this.isLogin = this.$store.getters.getisLogin;
    this.userID = this.$store.getters.getuserID;
    this.token = this.$store.getters.gettoken;

  },
  mounted() {
    
  },

  methods: {
  }

};
</script>

<style scoped>

</style>