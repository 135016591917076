import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

  const state= {
    userMsg: {
      isLogin: false,
      userID: '',
      userName: '',
      IsProUser: false,
      token: '',

      ChatUseNum: 0,
      NotLoginChatNum: 0,
      DocAddNum: 0,
    },
    systemMsg: {
      leftBarTag: true,
      funcName: '',
      nowRouter: '',
      PromptBoomSnackBar: '',
    },
    powerChatMsg: {
      RobotLinks: [
        {
          'botName': 'PowerChat',
          'botDescription': `Your personal AI assistant`,
          'botID': 'default',
          'photoUrl': require("@/assets/P.png"),
        }
      ],
      PowerChatRobotLinks: {
        'default': []
      },
      RobotID: '',
      RobotName: '',
      RobotDescription: '',
      ExploreRobotLinks: {},
      ExploreLoading: false,
      RobotLinksLoading: false,
      refreshRobotLinks: false,
    },
    powerFileMsg: {
      DocLinks: [],
      selectedFiles: [],
      refreshDocLinks: false,
      DocLinksLoading: false,
      showDocUrl: {},
      showDocName: '',
    },
    powerRewriteMsg: {
      powerRewriteHeadMsg: {},
      powerRewriteRes: '',
      powerRewriteTryDemo: false,
      powerRewriteMainHeight: false,
    },
    powerImitateMsg: {
      powerImitateHeadMsg: {},
      powerImitateRes: '',
      powerImitateTryDemo: false,
      powerImitateMainHeight: false,
    },
    powerWriterMsg: {
      powerWriterRes: '',
      powerWriterHeadMsg: {},
      powerWriterTryDemo: false,
      powerWriterMainHeight: false,
    },
    powerSummarizerMsg: {
      powerSummarizerHeadMsg: {},
      powerSummarizerRes: '',
      powerSummarizerTryDemo: false,
      powerSummarizerMainHeight: false,
    },
    powerTranslateMsg: {
      powerTranslateHeadMsg: {},
      powerTranslateRes: '',
      powerTranslateMainHeight: false,
    },
    powerGrammarFixMsg: {
      powerGrammarFixHeadMsg: {},
      powerGrammarFixRes: '',
      powerGrammarFixTryDemo: false,
      powerGrammarFixMainHeight: false,
    },
    powerCodeExplainerMsg: {
      powerCodeExplainerHeadMsg: {},
      powerCodeExplainerRes: '',
      powerCodeExplainerTryDemo: false,
      powerCodeExplainerMainHeight: false,
    },
    powerFewShotMsg: {
      powerFewShotHeadMsg: {},
      powerFewShotRes: '',
      powerFewShotTryDemo: false,
      powerFewShotMainHeight: false,
    },
    powerPictureMsg: {
      powerPictureHeadMsg: {},
      powerPictureRes: '',
      powerPictureTryDemo: false,
      powerPictureMainHeight: false,
    },
  }

  const getters= {
    // userMsg
    getisLogin() {
      return state.userMsg.isLogin
    },
    getuserID() {
      return state.userMsg.userID
    },
    getuserName() {
      return state.userMsg.userName
    },
    getIsProUser() {
      return state.userMsg.IsProUser
    },
    gettoken() {
      return state.userMsg.token
    },
    getChatUseNum() {
      return state.userMsg.ChatUseNum
    },
    getNotLoginChatNum() {
      return state.userMsg.NotLoginChatNum
    },
    getDocAddNum() {
      return state.userMsg.DocAddNum
    },

    // systemMsg
    getleftBarTag() {
      return state.systemMsg.leftBarTag
    },
    getfuncName() {
      return state.systemMsg.funcName
    },
    getnowRouter() {
      return state.systemMsg.nowRouter
    },
    getPromptBoomSnackBar() {
      return state.systemMsg.PromptBoomSnackBar
    },

    // powerChatMsg
    getRobotLinks() {
      return state.powerChatMsg.RobotLinks
    },
    getPowerChatRobotLinks() {
      return state.powerChatMsg.PowerChatRobotLinks
    },
    getRobotID() {
      return state.powerChatMsg.RobotID
    },
    getRobotName() {
      return state.powerChatMsg.RobotName
    },
    getRobotDescription() {
      return state.powerChatMsg.RobotDescription
    },
    getExploreRobotLinks() {
      return state.powerChatMsg.ExploreRobotLinks
    },
    getExploreLoading() {
      return state.powerChatMsg.ExploreLoading
    },
    getRobotLinksLoading() {
      return state.powerChatMsg.RobotLinksLoading
    },
    getrefreshRobotLinks() {
      return state.powerChatMsg.refreshRobotLinks
    },

    // powerFileMsg
    getDocLinks() {
      return state.powerFileMsg.DocLinks
    },
    getselectedFiles() {
      return state.powerFileMsg.selectedFiles
    },
    getrefreshDocLinks() {
      return state.powerFileMsg.refreshDocLinks
    },
    getDocLinksLoading() {
      return state.powerFileMsg.DocLinksLoading
    },
    getshowDocUrl() {
      return state.powerFileMsg.showDocUrl
    },
    getshowDocName() {
      return state.powerFileMsg.showDocName
    },

    // powerRewriteMsg
    getpowerRewriteHeadMsg() {
      return state.powerRewriteMsg.powerRewriteHeadMsg
    },
    getpowerRewriteRes() {
      return state.powerRewriteMsg.powerRewriteRes
    },
    getpowerRewriteTryDemo() {
      return state.powerRewriteMsg.powerRewriteTryDemo
    },
    getpowerRewriteMainHeight() {
      return state.powerRewriteMsg.powerRewriteMainHeight
    },

    // powerImitateMsg
    getpowerImitateHeadMsg() {
      return state.powerImitateMsg.powerImitateHeadMsg
    },
    getpowerImitateRes() {
      return state.powerImitateMsg.powerImitateRes
    },
    getpowerImitateTryDemo() {
      return state.powerImitateMsg.powerImitateTryDemo
    },
    getpowerImitateMainHeight() {
      return state.powerImitateMsg.powerImitateMainHeight
    },

    // powerWriterMsg
    getpowerWriterRes() {
      return state.powerWriterMsg.powerWriterRes
    },
    getpowerWriterHeadMsg() {
      return state.powerWriterMsg.powerWriterHeadMsg
    },
    getpowerWriterTryDemo() {
      return state.powerWriterMsg.powerWriterTryDemo
    },
    getpowerWriterMainHeight() {
      return state.powerWriterMsg.powerWriterMainHeight
    },

    // powerSummarizerMsg
    getpowerSummarizerHeadMsg() {
      return state.powerSummarizerMsg.powerSummarizerHeadMsg
    },
    getpowerSummarizerRes() {
      return state.powerSummarizerMsg.powerSummarizerRes
    },
    getpowerSummarizerTryDemo() {
      return state.powerSummarizerMsg.powerSummarizerTryDemo
    },
    getpowerSummarizerMainHeight() {
      return state.powerSummarizerMsg.powerSummarizerMainHeight
    },

    // powerTranslateMsg
    getpowerTranslateHeadMsg() {
      return state.powerTranslateMsg.powerTranslateHeadMsg
    },
    getpowerTranslateRes() {
      return state.powerTranslateMsg.powerTranslateRes
    },
    getpowerTranslateMainHeight() {
      return state.powerTranslateMsg.powerTranslateMainHeight
    },

    // powerGrammarFixMsg
    getpowerGrammarFixHeadMsg() {
      return state.powerGrammarFixMsg.powerGrammarFixHeadMsg
    },
    getpowerGrammarFixRes() {
      return state.powerGrammarFixMsg.powerGrammarFixRes
    },
    getpowerGrammarFixTryDemo() {
      return state.powerGrammarFixMsg.powerGrammarFixTryDemo
    },
    getpowerGrammarFixMainHeight() {
      return state.powerGrammarFixMsg.powerGrammarFixMainHeight
    },
    
    // powerCodeExplainerMsg
    getpowerCodeExplainerHeadMsg() {
      return state.powerCodeExplainerMsg.powerCodeExplainerHeadMsg
    },
    getpowerCodeExplainerRes() {
      return state.powerCodeExplainerMsg.powerCodeExplainerRes
    },
    getpowerCodeExplainerTryDemo() {
      return state.powerCodeExplainerMsg.powerCodeExplainerTryDemo
    },
    getpowerCodeExplainerMainHeight() {
      return state.powerCodeExplainerMsg.powerCodeExplainerMainHeight
    },

    // powerFewShotMsg
    getpowerFewShotHeadMsg() {
      return state.powerFewShotMsg.powerFewShotHeadMsg
    },
    getpowerFewShotRes() {
      return state.powerFewShotMsg.powerFewShotRes
    },
    getpowerFewShotTryDemo() {
      return state.powerFewShotMsg.powerFewShotTryDemo
    },
    getpowerFewShotMainHeight() {
      return state.powerFewShotMsg.powerFewShotMainHeight
    },

    // powerPictureMsg
    getpowerPictureHeadMsg() {
      return state.powerPictureMsg.powerPictureHeadMsg
    },
    getpowerPictureRes() {
      return state.powerPictureMsg.powerPictureRes
    },
    getpowerPictureTryDemo() {
      return state.powerPictureMsg.powerPictureTryDemo
    },
    getpowerPictureMainHeight() {
      return state.powerPictureMsg.powerPictureMainHeight
    },
  }

  const mutations= {
    // userMsg
    HandleisLogin(state, data) {
      state.userMsg.isLogin = data; 
    },
    HandleuserID(state, data) {
      state.userMsg.userID = data; 
    },
    HandleuserName(state, data) {
      state.userMsg.userName = data; 
    },
    HandleIsProUser(state, data) {
      state.userMsg.IsProUser = data; 
    },
    Handletoken(state, data) {
      state.userMsg.token = data; 
    },
    HandleChatUseNum(state, num) {
      state.userMsg.ChatUseNum = num; 
    },
    HandleNotLoginChatNum(state, num) {
      state.userMsg.NotLoginChatNum = num; 
    },
    HandleDocAddNum(state, num) {
      state.userMsg.DocAddNum = num; 
    },
    

    // systemMsg
    HandleleftBarTag(state, data) {
      state.systemMsg.leftBarTag = data; 
    },
    HandlefuncName(state, data) {
      state.systemMsg.funcName = data; 
    },
    HandlenowRouter(state, data) {
      state.systemMsg.nowRouter = data; 
    },
    HandlePromptBoomSnackBar(state, data) {
      state.systemMsg.PromptBoomSnackBar = data; 
    },

    // powerChatMsg
    HandleRobotLinks(state, data) {
      state.powerChatMsg.RobotLinks = data; 
    },
    HandlePowerChatRobotLinks(state, data) {
      state.powerChatMsg.PowerChatRobotLinks = data; 
    },
    HandleRobotID(state, data) {
      state.powerChatMsg.RobotID = data; 
    },
    HandleRobotName(state, data) {
      state.powerChatMsg.RobotName = data; 
    },
    HandleRobotDescription(state, data) {
      state.powerChatMsg.RobotDescription = data; 
    },
    HandleExploreRobotLinks(state, data) {
      state.powerChatMsg.ExploreRobotLinks = data; 
    },
    HandleExploreLoading(state, data) {
      state.powerChatMsg.ExploreLoading = data; 
    },
    HandleRobotLinksLoading(state, data) {
      state.powerChatMsg.RobotLinksLoading = data; 
    },
    HandlerefreshRobotLinks(state, data) {
      state.powerChatMsg.refreshRobotLinks = data; 
    },

    // powerFileMsg
    HandleDocLinks(state, data) {
      state.powerFileMsg.DocLinks = data; 
    },
    HandleselectedFiles(state, data) {
      state.powerFileMsg.selectedFiles = data; 
    },
    HandlerefreshDocLinks(state, data) {
      state.powerFileMsg.refreshDocLinks = data; 
    },
    HandleDocLinksLoading(state, data) {
      state.powerFileMsg.DocLinksLoading = data; 
    },
    HandleshowDocUrl(state, data) {
      state.powerFileMsg.showDocUrl = data; 
    },
    HandleshowDocName(state, data) {
      state.powerFileMsg.showDocName = data; 
    },

    // powerRewriteMsg
    HandlepowerRewriteHeadMsg(state, data) {
      state.powerRewriteMsg.powerRewriteHeadMsg = data; 
    },
    HandlepowerRewriteRes(state, data) {
      state.powerRewriteMsg.powerRewriteRes = data; 
    },
    HandlepowerRewriteTryDemo(state, data) {
      state.powerRewriteMsg.powerRewriteTryDemo = data; 
    },
    HandlepowerRewriteMainHeight(state, data) {
      state.powerRewriteMsg.powerRewriteMainHeight = data; 
    },

    // powerImitateMsg
    HandlepowerImitateHeadMsg(state, data) {
      state.powerImitateMsg.powerImitateHeadMsg = data; 
    },
    HandlepowerImitateRes(state, data) {
      state.powerImitateMsg.powerImitateRes = data; 
    },
    HandlepowerImitateTryDemo(state, data) {
      state.powerImitateMsg.powerImitateTryDemo = data; 
    },
    HandlepowerImitateMainHeight(state, data) {
      state.powerImitateMsg.powerImitateMainHeight = data; 
    },

    // powerWriterMsg
    HandlepowerWriterRes(state, data) {
      state.powerWriterMsg.powerWriterRes = data; 
    },
    HandlepowerWriterHeadMsg(state, data) {
      state.powerWriterMsg.powerWriterHeadMsg = data; 
    },
    HandlepowerWriterTryDemo(state, data) {
      state.powerWriterMsg.powerWriterTryDemo = data; 
    },
    HandlepowerWriterMainHeight(state, data) {
      state.powerWriterMsg.powerWriterMainHeight = data; 
    },

    // powerSummarizerMsg
    HandlepowerSummarizerHeadMsg(state, data) {
      state.powerSummarizerMsg.powerSummarizerHeadMsg = data; 
    },
    HandlepowerSummarizerRes(state, data) {
      state.powerSummarizerMsg.powerSummarizerRes = data; 
    },
    HandlepowerSummarizerTryDemo(state, data) {
      state.powerSummarizerMsg.powerSummarizerTryDemo = data; 
    },
    HandlepowerSummarizerMainHeight(state, data) {
      state.powerSummarizerMsg.powerSummarizerMainHeight = data; 
    },

    // powerTranslateMsg
    HandlepowerTranslateHeadMsg(state, data) {
      state.powerTranslateMsg.powerTranslateHeadMsg = data; 
    },
    HandlepowerTranslateRes(state, data) {
      state.powerTranslateMsg.powerTranslateRes = data; 
    },
    HandlepowerTranslateMainHeight(state, data) {
      state.powerTranslateMsg.powerTranslateMainHeight = data; 
    },

    // powerGrammarFixMsg
    HandlepowerGrammarFixHeadMsg(state, data) {
      state.powerGrammarFixMsg.powerGrammarFixHeadMsg = data; 
    },
    HandlepowerGrammarFixRes(state, data) {
      state.powerGrammarFixMsg.powerGrammarFixRes = data; 
    },
    HandlepowerGrammarFixTryDemo(state, data) {
      state.powerGrammarFixMsg.powerGrammarFixTryDemo = data; 
    },
    HandlepowerGrammarFixMainHeight(state, data) {
      state.powerGrammarFixMsg.powerGrammarFixMainHeight = data; 
    },

    // powerCodeExplainerMsg
    HandlepowerCodeExplainerHeadMsg(state, data) {
      state.powerCodeExplainerMsg.powerCodeExplainerHeadMsg = data; 
    },
    HandlepowerCodeExplainerRes(state, data) {
      state.powerCodeExplainerMsg.powerCodeExplainerRes = data; 
    },
    HandlepowerCodeExplainerTryDemo(state, data) {
      state.powerCodeExplainerMsg.powerCodeExplainerTryDemo = data; 
    },
    HandlepowerCodeExplainerMainHeight(state, data) {
      state.powerCodeExplainerMsg.powerCodeExplainerMainHeight = data; 
    },

    // powerFewShotMsg
    HandlepowerFewShotHeadMsg(state, data) {
      state.powerFewShotMsg.powerFewShotHeadMsg = data; 
    },
    HandlepowerFewShotRes(state, data) {
      state.powerFewShotMsg.powerFewShotRes = data; 
    },
    HandlepowerFewShotTryDemo(state, data) {
      state.powerFewShotMsg.powerFewShotTryDemo = data; 
    },
    HandlepowerFewShotMainHeight(state, data) {
      state.powerFewShotMsg.powerFewShotMainHeight = data; 
    },

    // powerPictureMsg
    HandlepowerPictureHeadMsg(state, data) {
      state.powerPictureMsg.powerPictureHeadMsg = data; 
    },
    HandlepowerPictureRes(state, data) {
      state.powerPictureMsg.powerPictureRes = data; 
    },
    HandlepowerPictureTryDemo(state, data) {
      state.powerPictureMsg.powerPictureTryDemo = data; 
    },
    HandlepowerPictureMainHeight(state, data) {
      state.powerPictureMsg.powerPictureMainHeight = data; 
    },
  }

export default new Vuex.Store({
    mutations:mutations,
    state:state,
    getters:getters,
})