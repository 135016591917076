<template>
  <div style="background-color: rgb(249, 249, 249);  width: 100%;">
    <div class="d-flex justify-center">
      <v-btn color="cyan" small text class="d-flex justify-center" @click="handleGetMainHeight">
        <v-icon size="30" color="cyan">mdi-chevron-down</v-icon>
      </v-btn>
    </div>
    <div class="pt-16 pb-16">
      <YA_TranslateContent1 />
    </div>
    <!-- <div class="pt-16 pb-16">
      <YA_Customers />
    </div> -->
  </div>
</template>

<script>
import YA_TranslateContent1 from '@/components/PowerTranslate/YA_TranslateContent1.vue';

export default {
  name: 'PowerTranslateContent',
  components: {
    YA_TranslateContent1,
  },
  data() {
    return {

    }
  },
  mounted() {

  },

  methods: {
    handleGetMainHeight() {
      this.$store.commit('HandlepowerTranslateMainHeight', true);
    },
  }

};
</script>

<style scoped>

</style>