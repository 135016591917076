<template>
  <div>
    <v-row class="justify-center">
      <v-col cols="6" sm="6" md="3" lg="3" xl="3" v-for="(aboutItem, index) in aboutLinks" :key="index">
        <template>
          <v-hover v-slot="{ hover }">
            <v-card
              rounded="xl"
              class="mx-auto"
              elevation="6"
            >
              <v-img
                :src=aboutItem.photoUrl
              >
                <v-expand-transition>
                  <div
                    v-if="hover"
                    :class=aboutItem.color
                    style="height: 100%; cursor: pointer;"
                    @click="handleRouter(aboutItem.funcName)"
                  >
                    <v-img
                      height="140"
                      contain
                      :src=aboutItem.personPhoto
                    ></v-img>
                    <span  v-if="!$vuetify.breakpoint.smAndDown" class="grey-blue--text text--darken-4 text-body-2 pa-2 mt-n4">{{ aboutItem.funcIntro }}</span>

                  </div>
                </v-expand-transition>
              </v-img>
              
            </v-card>
          </v-hover>
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>


export default {
  name: 'YA_AboutProducts',
  components: {
  },
  data() {
    return {
      aboutLinks: [
        {
          'photoUrl': require("@/assets/AboutPic/pic_About_powerChat.png"),
          'color': 'd-flex flex-wrap transition-fast-in-fast-out v-card--reveal text-h2 white--text teal lighten-1',
          'personPhoto': require("@/assets/contentPic/pic_chat_mainPhoto.png"),
          'funcName': 'PowerChat',
          'funcIntro': 'PowerChat lets you ask questions, get instant answers, and have back-and-forth conversations with AI.',
        },
        // {
        //   'photoUrl': require("@/assets/AboutPic/pic_About_powerFile.png"),
        //   'color': 'd-flex flex-wrap transition-fast-in-fast-out v-card--reveal text-h2 white--text red darken-1',
        //   'personPhoto': require("@/assets/contentPic/pic_file_mainPhoto.png"),
        //   'funcName': 'PowerFile',
        //   'funcIntro': 'Chat with any PDF document from legal agreements to financial reports, PowerFile brings your documents to life.',
        // },
        {
          'photoUrl': require("@/assets/AboutPic/pic_About_powerWrite.png"),
          'color': 'd-flex flex-wrap transition-fast-in-fast-out v-card--reveal text-h2 white--text yellow darken-3',
          'personPhoto': require("@/assets/contentPic/pic_writer_mainPhoto.png"),
          'funcName': 'PowerWriter',
          'funcIntro': 'PowerWriter is an AI-powered tool that generates text based on user input and selected options.',
        },
        {
          'photoUrl': require("@/assets/AboutPic/pic_About_powerRewrite.png"),
          'color': 'd-flex flex-wrap transition-fast-in-fast-out v-card--reveal text-h2 white--text purple darken-1',
          'personPhoto': require("@/assets/contentPic/pic_rewrite_mainPhoto.png"),
          'funcName': 'PowerRewrite',
          'funcIntro': 'PowerRewrite helps you easily personalize and tailor your text to fit your desired tone.',
        },
        {
          'photoUrl': require("@/assets/AboutPic/pic_About_powerImitate.png"),
          'color': 'd-flex flex-wrap transition-fast-in-fast-out v-card--reveal text-h2 white--text red darken-1',
          'personPhoto': require("@/assets/contentPic/pic_file_mainPhoto.png"),
          'funcName': 'PowerImitate',
          'funcIntro': 'PowerImitate helps you imitate a style and write.',
        },
        {
          'photoUrl': require("@/assets/AboutPic/pic_About_powerSummarizer.png"),
          'color': 'd-flex flex-wrap transition-fast-in-fast-out v-card--reveal text-h2 white--text deep-purple darken-1',
          'personPhoto': require("@/assets/contentPic/pic_summarizer_mainPhoto.png"),
          'funcName': 'PowerSummarizer',
          'funcIntro': 'The powerful AI tools for summarizing any text with a click of a button.',
        },
        {
          'photoUrl': require("@/assets/AboutPic/pic_About_powerTranslate.png"),
          'color': 'd-flex flex-wrap transition-fast-in-fast-out v-card--reveal text-h2 white--text cyan darken-1',
          'personPhoto': require("@/assets/contentPic/pic_translate_mainPhoto.png"),
          'funcName': 'PowerTranslate',
          'funcIntro': 'PowerTranslate is a product that can innovatively provide excellent and accurate translations.',
        },
        {
          'photoUrl': require("@/assets/AboutPic/pic_About_powerGrammar.png"),
          'color': 'd-flex flex-wrap transition-fast-in-fast-out v-card--reveal text-h2 white--text green darken-1',
          'personPhoto': require("@/assets/contentPic/pic_grammar_mainPhoto.png"),
          'funcName': 'PowerGrammarFix',
          'funcIntro': 'PowerGrammarFix is an intelligent grammar checking tool that eliminates misunderstandings and improves expression for you!',
        },
        // {
        //   'photoUrl': require("@/assets/AboutPic/pic_About_powerCode.png"),
        //   'color': 'd-flex flex-wrap transition-fast-in-fast-out v-card--reveal text-h2 white--text grey darken-1',
        //   'personPhoto': require("@/assets/contentPic/pic_code_mainPhoto.png"),
        //   'funcName': 'PowerCodeExplainer',
        //   'funcIntro': 'Get an extensive explanation of any piece of code.',
        // },
        {
          'photoUrl': require("@/assets/AboutPic/pic_About_powerFewShot.png"),
          'color': 'd-flex flex-wrap transition-fast-in-fast-out v-card--reveal text-h2 white--text indigo darken-1',
          'personPhoto': require("@/assets/contentPic/pic_fewshot_mainPhoto.png"),
          'funcName': 'PowerFewShot',
          'funcIntro': 'PowerFewShot is an intelligent product that generates imitated content based on user input examples and the content to be imitated.',
        },
        {
          'photoUrl': require("@/assets/AboutPic/pic_About_powerTelegram.png"),
          'color': 'd-flex flex-wrap transition-fast-in-fast-out v-card--reveal text-h2 white--text blue lighten-1',
          'personPhoto': require("@/assets/contentPic/pic_telegram_mainPhoto.png"),
          'funcName': 'PowerTelegram',
          'funcIntro': "Enhance any conversation with the power of ChatGPT on Telegram. Summon it anywhere, anytime to answer any question without leaving Telegram.",
        },
      ]
    }
  },
  created() {

  },
  mounted() {
    
  },

  methods: {
    handleRouter(funcName) {
      if (this.$router.currentRoute.path !== '/' + funcName) {
        this.$router.push('/' + funcName);
      }
    }
  }

};
</script>

<style scoped>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  position: absolute;
  width: 100%;
}
</style>