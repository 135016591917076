<template>
  <div style="background-color: rgb(249, 249, 249);  width: 100%;">
    <div class="d-flex justify-center">
      <v-btn color="purple" small text class="d-flex justify-center" @click="handleGetMainHeight">
        <v-icon size="30" color="purple">mdi-chevron-down</v-icon>
      </v-btn>
    </div>
    <div class="pt-16 pb-16">
      <YA_RewriteContent1 />
    </div>
    <!-- <div class="pt-16 pb-16">
      <YA_Customers />
    </div> -->
  </div>
</template>

<script>
import YA_RewriteContent1 from '@/components/PowerRewrite/YA_RewriteContent1.vue';

export default {
  name: 'PowerRewriteContent',
  components: {
    YA_RewriteContent1,
  },
  data() {
    return {

    }
  },
  mounted() {

  },

  methods: {
    handleGetMainHeight() {
      this.$store.commit('HandlepowerRewriteMainHeight', true);
    },
  }

};
</script>

<style scoped>

</style>