
<template>
  <div>
    <v-container class="my-10 py-5" >
      <v-row class="justify-center" align="stretch">
        <v-col cols="10" sm="10" md="5" lg="5" xl="5" class="d-flex align-center flex-wrap">
          <div>
            <p class="text-h5 text-sm-h4 text-md-h3 text-lg-h3 text-xl-h3 font-weight-black">Our vision 😉</p>
            <p class="mt-5 text-body-2 text-sm-body-1 text-md-body-1 text-lg-body-1 text-xl-body-1 font-weight-black">
              We leverage the strength of artificial intelligence to enrich human life. By employing cutting-edge technology, we craft transformative solutions that redefine the future. Our portfolio of AI products, ranging from intuitive chatbots to sophisticated systems, addresses today's most pressing challenges. Guided by our vision, we strive to illuminate a brighter future for all, thereby unlocking the untapped potential of tomorrow.
            </p>
          </div>
        </v-col>
        <v-col cols="10" sm="10" md="5" lg="5" xl="5">
          <v-card elevation="2" rounded="xl" outlined>
            <v-img src="@/assets/pic_about2.jpg" rounded contain></v-img>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'YA_HomeContent2',
  data() {
    return {

    }
  },
  mounted() {

  },

  methods: {
  }

};
</script>

<style scoped>

</style>