<template>
  <div>
    <YA_Snackbar ref="YA_SnackbarRef" />

    <v-row class="justify-center" no-gutters>
      <v-col cols="12" sm="12" md="2" lg="2" xl="2">
        <PowerChatLeftBar />
      </v-col>
      <v-col cols="12" sm="12" md="10" lg="10" xl="10">
        <keep-alive>
          <router-view />
        </keep-alive>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PowerChatLeftBar from "@/components/PowerChat/PowerChatLeftBar.vue";
import YA_Snackbar from '@/components/WebBar/YA_Snackbar.vue';
import { requestPost } from '@/utils/SystemUtils/request.js';
import { getLocalStorage, setLocalStorage, removeLocalStorage } from '@/utils/SystemUtils/localStorage.js'


export default {
  name: 'PowerChatMain',
  components: {
    PowerChatLeftBar,
    YA_Snackbar,
  },
  data() {
    return {
      userID: '',
      token: '',
      userName: '',
    }
  },
  created() {
    this.$store.watch(
      (state) => state.userMsg.isLogin, () => {
        this.isLogin = this.$store.getters.getisLogin;
        this.userID = this.$store.getters.getuserID;
        this.token = this.$store.getters.gettoken;
        if (this.isLogin) {
          this.handleGetAllBot();
          this.handleGetExploreBot();
        }
      }, {
        deep: true
      }
    );
    this.isLogin = this.$store.getters.getisLogin;
    this.userID = this.$store.getters.getuserID;
    this.token = this.$store.getters.gettoken;
    if (this.isLogin) {
      this.handleGetAllBot();
      this.handleGetExploreBot();
    }

    this.$store.watch(
      (state) => state.powerChatMsg.refreshRobotLinks, () => {
        if (this.$store.getters.getrefreshRobotLinks) {
          this.handleGetAllBot();
          this.$store.commit('HandlerefreshRobotLinks', false);
        }
      }, {
        deep: true
      }
    );
  },
  mounted() {

  },

  methods: {
    async handleGetExploreBot() {
      this.$store.commit('HandleExploreLoading', true);

      let request_header = {
        email: getLocalStorage('PromptBoomUser'),
        token: getLocalStorage('PromptBoomToken'),
      };
      let request_json = {};
      let request_url = '/requestGetExploreBot';

      const res = await requestPost(request_header, request_json, request_url);

      if (res.statusCode == 1) {
        let ExploreRobotLinks = res.data;
        ExploreRobotLinks.forEach ((item)=>{
          item.ULoading = false;
        })

        this.$store.commit('HandleExploreRobotLinks', ExploreRobotLinks);
        this.$store.commit('HandleExploreLoading', false);

      } else {
        this.$refs.YA_SnackbarRef.handleSnackbar('Failed to retrieve bots: ' + res.statusInfo);
      }
    },
    async handleGetAllBot() {
      this.$store.commit('HandleRobotLinksLoading', true);

      let request_header = {
        email: getLocalStorage('PromptBoomUser'),
        token: getLocalStorage('PromptBoomToken')
      };
      let request_json = {};

      let request_url = '/requestGetAllBot';
      const res = await requestPost(request_header, request_json, request_url);

      if (res.statusCode == 1) {

        let PowerChatRobotLinks = {}
        res.data.forEach(item => {
          PowerChatRobotLinks[item.botID] = []
        });
        
        this.$store.commit('HandlePowerChatRobotLinks', PowerChatRobotLinks);

        res.data.forEach ((item)=>{
          if (item.botID == 'default') {
            item.photoUrl = require("@/assets/P.png")
          } else {
            if (item.photoUrl === null) {
              item.photoUrl = require("@/assets/RobotIcon.png")
            }
          }
          item.showButtons = false;
        })
        this.$store.commit('HandleRobotLinks', res.data);

        this.$store.commit('HandleRobotLinksLoading', false);


      } else {
        this.$refs.YA_SnackbarRef.handleSnackbar('Failed to retrieve BotLink: ' + res.statusInfo);
      }
    },
  }

};
</script>

<style scoped>

</style>