<template>
  <div>
    <v-card elevation="0" rounded="lg">
      <v-container class="d-flex justify-space-between">
        <span class="indigo--text text--darken-3 text-body-1 font-weight-bold mt-1">
          <v-icon color="indigo darken-4" class="mt-n1">mdi-collage</v-icon>
          Few-Shot Learning:
        </span>
        <v-btn color="indigo" class="mr-2" small @click="handleTryDemo">
          <h4 class="text-capitalize white--text font-weight-black">Try demo</h4>
          <v-icon size="20" class="ml-1" color="white">mdi-emoticon-wink</v-icon>
        </v-btn>
      </v-container>
      <div class="d-flex justify-center mt-n4">
        <v-row justify-center no-gutters>
          <v-col cols="12" sm="12" md="5" lg="5" xl="5">
            <v-container>
              <v-textarea
                class="mt-2"
                v-model="powerFewShotHeadInput"
                color="indigo"
                height="100"
                outlined
                clearable
                label="Enter your Few-Shot example input message..."
                maxlength="1000"
                no-resize
                counter
                @change="handlePowerFewShotHeadChange"
              ></v-textarea>
            </v-container>
          </v-col>

          <v-col cols="12" sm="12" md="2" lg="2" xl="2" class="d-flex justify-center align-center">
            <v-icon color="indigo darken-4" v-if="!$vuetify.breakpoint.smAndDown" class="mt-n1">mdi-chevron-triple-right</v-icon>
            <v-icon color="indigo darken-4" v-else class="mt-n1">mdi-chevron-triple-down</v-icon>
          </v-col>

          <v-col cols="12" sm="12" md="5" lg="5" xl="5">
            <v-container>
              <v-textarea
                class="mt-2"
                v-model="powerFewShotHeadOutput"
                color="indigo"
                height="100"
                outlined
                clearable
                label="Enter your Few-Shot example output message..."
                maxlength="1000"
                no-resize
                counter
                @change="handlePowerFewShotHeadChange"
              ></v-textarea>
            </v-container>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'PowerFewShotHead',
  data() {
    return {
      powerFewShotHeadInput: '',
      powerFewShotHeadOutput: '',
    }
  },
  mounted() {
    this.handlePowerFewShotHeadChange()
  },

  methods: {
    handlePowerFewShotHeadChange() {
      let powerFewShotHeadMsg = {
        'input': this.powerFewShotHeadInput,
        'output': this.powerFewShotHeadOutput
      }
      this.$store.commit('HandlepowerFewShotHeadMsg', powerFewShotHeadMsg);
    },
    handleTryDemo() {
      this.powerFewShotHeadInput = 'This is awesome!\nThis is bad\n';
      this.powerFewShotHeadOutput = 'Positive\nNegative';
      this.handlePowerFewShotHeadChange();
      this.$store.commit('HandlepowerFewShotTryDemo', true);
    }
  }

};
</script>

<style scoped>

</style>